import React, { useState } from "react";
import "./DropdownMenu.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const DropdownMenu3 = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLinkClick = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a className="ok">Inovations</a>
      {isHovered && (
        <div className="dropdown-content-3" style={{ height: "auto" }}>
          <h3 className="allplatforms">
            All Businesses <FontAwesomeIcon icon={faArrowRight} />
          </h3>

          <hr className="linehr" />
          <div
            id="business-container"
            className="container"
            onClick={handleLinkClick}
          >
            <div className="service-row">
              <div className="service-column">
                <div className="row">
                  <Link to="/businesses/drone" className="abc">
                    Drone Solution
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                
                <div className="row">
                  <Link to="/service/publicsector" className="abc">
                    Public Sector
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                <div className="row">
                  <Link to="/businesses/Automative" className="abc">
                    Automotive
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                <div className="row">
                  <Link to="/businesses/digitalmarketing" className="abc">
                    Digital Marketing & Strategy
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
              </div>

              <div className="service-column">
              <div className="row">
                  <Link to="/businesses/finance" className="abc">
                    Financial Services
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                <div className="row">
                  <Link to="/service/informationservice" className="abc">
                    Information Services
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                
                
                <div className="row">
                  <Link to="/service/educationalservice" className="abc">
                    {" "}
                    Education
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                <div className="row">
                  <Link to="" className="abc">
                    {" "}
                    
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
              </div>

              <div className="service-column">
                <div className="row">
                  <Link to="/businesses/healthcare" className="abc">
                    Helthcare
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                
                <div className="row">
                  <Link to="/businesses/suply" className="abc">
                    Logistics & Supplychain
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                <div className="row">
                  <Link to="businesses/ecommerce" className="abc">
                    Retail & Ecommerce
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
                <div className="row">
                  <Link to="businesses/ecommerce" className="abc">
                   
                  </Link>
                </div>{" "}
                <hr className="media-query-hr" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu3;
