import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordians from "./Accordians.js";
import SOP from "./SOP.js";
// import "./cyberCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function SdWan() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
  let items = [
    {
      title: "How can SD-WAN reduce my business costs? ",
      content:
        "By optimizing bandwidth usage and reducing the need for expensive proprietary hardware, SD-WAN can lower operational and capital expenses. ",
    },
    {
      title: " Is Net-Horizon SD-WAN secure? ",
      content:
        " Yes, security is a top priority, and our service includes encryption, micro-segmentation, and built-in firewalls for comprehensive security. ",
    },
    {
      title: "Can NetHorizon SD-WAN support cloud applications? ",
      content:
        "  Absolutely. Our SD-WAN solution is designed to support and enhance cloud application performance, making it ideal for cloud-first enterprises. ",
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">Purpose and Vision of our SD-WAN Service</p>

      <hr />

      <div className="modal-sub-sk">
        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Purpose</h5>
            <p>
              To provide businesses with a scalable, secure, and efficient
              wide-area network solution that simplifies operations, reduces
              costs, and enhances connectivity.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Vision</h5>
            <p>
              To revolutionize business connectivity by making SD-WAN technology
              accessible, manageable, and beneficial for organizations of all
              sizes, paving the way for a future of seamless network
              integration.
            </p>
          </div>
        </div>

        {/* <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Enhanced Digital Presence</h5>
              <p>For tutors looking to establish
      or enhance their digital presence, online tutor platforms offer a
      professional platform to showcase their expertise and attract students.</p>
          </div>
      </div> */}

        {/* <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Maximized Teaching Impact</h5>
              <p>Ultimately, the purpose
      of an online tutor platform is to maximize the teaching impact of tutors
      by providing them with the tools and support they need to deliver
      high-quality instruction in a secure and effective online environment.</p>
          </div>
      </div> */}
      </div>

      {/* <br/> <strong>Design, Development, and Implementation</strong>
 
           Custom software solutions tailored to organizational needs enhance operational efficiency and competitiveness through ADM Services.
         <br/><br/>
        <strong>Application Maintenance</strong>
        Ongoing maintenance ensures software functionality, security, and performance, meeting evolving business demands.
 
          <br/><br/><strong>System Integration</strong>
          Seamless communication between systems enhances operational efficiency and decision-making through ADM Services' system integration.
 
          <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">SD-WAN services </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empowering Your Network to Drive Your Business Forward"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              NetHorizon SD-WAN is a managed service that delivers
              software-defined wide-area networking to your business. Our
              solution offers centralized control and agile management of
              multiple network connections, improving traffic routing, and
              ensuring optimal application performance across your enterprise
              network.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/sd-wan.jpg"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Customized Network Solutions
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Tailored SD-WAN configurations that match your specific business
                requirements.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Centralized Management
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              A unified control plane for monitoring and managing your entire
              network infrastructure.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Enhanced Security
            </strong>
            <p class="aligned-paragraph item3text">
              Integrated security protocols and features to protect against
              threats and ensure data integrity.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Bandwidth Optimization
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Intelligent path control and application-aware routing to maximize
              bandwidth efficiency and reduce costs.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Zero-Touch Provisioning
            </strong>
            <p class="aligned-paragraph item3text">
              Easy deployment of new branches or changes with minimal manual
              intervention.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Vendor-Neutral Architecture
            </strong>
            <p class="aligned-paragraph item3text">
              Compatibility with various hardware vendors, avoiding vendor
              lock-in and allowing for best-in-class hardware options.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Performance Monitoring and Analytics
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Our comprehensive tools and analytics dashboards offer real-time
              insights into network traffic, application performance, and user
              behavior.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">USP of SD-WAN Services</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Flexibility and Scalability{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/presentation.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Flexibility and Scalability</h2>
                      <p>
                        Our SD-WAN service grows with your business, adapting to
                        new challenges and opportunities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Expert Support
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/social-care.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expert Support</h2>
                      <p>
                        Round-the-clock professional support and managed
                        services from certified network experts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Improved Performance{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/analytics.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Improved Performance</h2>
                      <p>
                        Advanced algorithms ensure that your critical
                        applications always have the bandwidth and resources
                        they need.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Global Reach
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/global-crisis.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Global Reach</h2>
                      <p>
                        Connect branches worldwide with a unified network
                        infrastructure, making it easy to manage international
                        operations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
         
          </div>
   
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
   
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
   
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
   
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
   
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}

      {/* section 6 */}
      <div className="newsection5-np">
        <h1 className="section5heading">Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Needs Assessment"
            text=" Understanding your current network architecture, challenges, and business goals. "
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Solution Design"
            text="Crafting a bespoke SD-WAN infrastructure that aligns with your requirements. "
            img="/Images/test.png"
          />
          <SOP
            headding="Implementation and Integration"
            text="Deploying the SD-WAN solution within your network without disrupting current operations. "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Continuous Monitoring"
            text="Real-time network monitoring to ensure peak performance and quick issue resolution. "
            img="/Images/report.png"
          />
          <SOP
            headding="Regular Updates"
            text="Keeping the network infrastructure up to date with the latest software and security patches. "
            img="/Images/planning.png"
          />
          <SOP
            headding="Performance Analysis"
            text="Ongoing analysis of network performance, with reports and recommendations for improvements. "
            img="/Images/icons8-date-to-48.png"
          />
        </div>
      </div>
      <div className="section5">
        <h1 className="section5heading">Use Cases</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Streamlining Retail Operations with a Unified Network Platform"
            description=""
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Optimizing Cloud Strategies for a Multi-National Corporation"
            description=""
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Enhancing Connectivity and Reducing Costs for a Financial Institution"
            description=" "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Agile Network Solutions for a Rapidly Scaling Tech Startup" 
            description=" "
            // linkUrl=""
          />

          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}

          {/* section 6 */}
        </div>
      </div>
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default SdWan;
