import React, { useEffect } from "react";
import "./template.css";
import AOS from "aos";
import Accordians from "./Accordians";

const Modal2 = ({ modals }) => {
  let items = [
    {
      title: "Urban Planning and Zoning",
      content:
        "Applications to aid in planning, zoning, and land use analysis, facilitating sustainable urban development. ",
    },
    {
      title: "Disaster Management and Emergency Response",
      content:
        "Real-time GIS solutions for disaster response, resource allocation, and recovery planning. ",
    },
    {
      title: "Natural Resource Management",
      content:
        "Applications for the sustainable management of resources like forests, water, and agriculture.",
    },
    {
      title: "Infrastructure Management",
      content:
        "GIS tools for tracking and maintaining critical infrastructure, from roads to utilities. ",
    },
    {
      title: "Public Health",
      content:
        " Systems for monitoring health data, disease outbreaks, and healthcare resources distribution. ",
    },
    {
      title: "Archaeology and Cultural Heritage",
      content:
        "Solutions for documenting, analyzing, and preserving historical and cultural sites. ",
    },
    {
      title: "Real Estate and Property Management",
      content:
        "Tools for property valuation, market analysis, and neighborhood assessment. ",
    },
    {
      title: "Custom Reporting and Dashboards",
      content:
        "Comprehensive reporting capabilities, including custom reports, graphs, and dashboards, based on spatial data analysis. ",
    },
  ];

  useEffect(() => {
    const handleModal = (modalIndex) => {
      const modal = document.getElementById(`myModal${modalIndex}`);
      const btn = document.getElementById(`myBtn${modalIndex}`);
      const closeDiv = document.getElementById(`close-np${modalIndex}`);

      const openModal = () => {
        modal.style.display = "block";
      };

      const closeModal = () => {
        modal.style.display = "none";
      };

      const handleOutsideClick = (event) => {
        if (event.target === modal) {
          closeModal();
        }
      };

      btn.addEventListener("click", openModal);
      closeDiv.addEventListener("click", closeModal);
      window.addEventListener("click", handleOutsideClick);

      return () => {
        btn.removeEventListener("click", openModal);
        closeDiv.removeEventListener("click", closeModal);
        window.removeEventListener("click", handleOutsideClick);
      };
    };

    modals.forEach((modal, index) => {
      handleModal(index);
    });
  }, [modals]);
  useEffect(() => {
    AOS.init({ duration: 4000 });
  });

  return (
    <>
      {modals.map((modal) => (
        <div key={modal.index}>
          <button id={`myBtn${modal.index}`} className="read1">
            {modal.buttonText}
          </button>

          <div id={`myModal${modal.index}`} className="modal">
            <div
              className="modal-content"
              style={{
                overflowY: "auto",

                opacity: 1,
                backgroundImage: "none",
              }}
            >
              <div id={`close-np${modal.index}`} className="close-np">
                <i className="fas fa-times closeicon"></i>
              </div>
              <div class="modal2headding" style={{ marginLeft: "10px" }}>
                Our Offerings
              </div>
              <Accordians items={items} data-aos="fade-up" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Modal2;

// Usage
/*
<ReadModal2
  modals={[
    { 
      index: 0,
      buttonText: "Button 1", 
      heading: "Modal Heading 1",
      text: "Some text for modal 1",
      modalContent: "Modal Content 1", 
      additionalData: { key1: "value1", key2: "value2" } 
    },
    { 
      index: 1,
      buttonText: "Button 2", 
      heading: "Modal Heading 2",
      text: "Some text for modal 2",
      modalContent: "Modal Content 2", 
      additionalData: { key3: "value3", key4: "value4" } 
    },
    // Add more objects for additional modals
  ]}
/>
*/
