import React, { useState, useEffect } from "react";
import "./Contact-Us.css"; // Import the CSS file for Contact component
import "./Contact-Us-mobile.css"; // Mobile styles
import "./template.css";
import Contactus2 from "./contactus2";
import AOS from "aos";
import "aos/dist/aos.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {
  const [isOpenamericas, setisOpenamericas] = useState(false);
  const [isOpenAcia, setisOpenAcia] = useState(false);
  const [isOpenmidalAfri, setisOpenmidalAfri] = useState(false);
  const [isOpenEurop, setisOpenEurop] = useState(false);
  const handalClickamericas = () => {
    setisOpenamericas(true);
  };

  const handalClickAcia = () => {
    setisOpenAcia(true);
  };
  const handalClickmidalAfri = () => {
    setisOpenmidalAfri(true);
  };
  const handalClickEurop = () => {
    setisOpenEurop(true);
  };

  const closeModal = () => {
    setisOpenamericas(false);
    setisOpenAcia(false);
    setisOpenmidalAfri(false);
    setisOpenEurop(false);
  };
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  //  const [company, setCompany] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  const handleCaptchaVerify = (response) => {
    console.log("Captcha verified:", response);
    // Add your logic here for captcha verification success
  };

  const handleCaptchaExpire = () => {
    console.log("Captcha expired");
    // Add your logic here for captcha expiration
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your form submission logic here
  };
  const americas = (
    <>
      <Contactus2
        city="USA"
        landmark_address="5790 SW 80th Pl ocala FL 34476,USA"
        Phone="+1 (305) 876-5547"
        email="info@digitechub.com"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3485.9971738105655!2d-82.2156315!3d29.105764799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e87fa6437ba5af%3A0x55b97d08c127f267!2s5790%20SW%2080th%20Pl%2C%20Ocala%2C%20FL%2034476%2C%20USA!5e0!3m2!1sen!2sin!4v1708354365378!5m2!1sen!2sin"
      />
    </>
  );

  const AciaP = (
    <>
      <Contactus2
        city="India"
        landmark_address="No. 1, Expert IT Technology Headquarters, SrNo- 97/7b, Madhavbaugh Villoo Poonawala Road, near Serum India Terminal, Pune, Maharashtra 412307"
        Phone="+91 8600187755"
        email="support@expertit.in"
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d30269.653694650224!2d73.89060495395138!3d18.496939485863596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sNo.%201%2C%20Expert%20IT%20Technology%20Headquarters%2C%20SrNo-%2097%2F7b%2C%20Madhavbaugh%20Villoo%20Poonawala%20Road%2C%20near%20Serum%20India%20Terminal%2C%20Pune%2C%20Maharashtra%20412307%22!5e0!3m2!1sen!2sin!4v1711614115288!5m2!1sen!2sin"
      />
      <hr />
      <Contactus2
        city="Goa"
        landmark_address="SF-F1, BETIM ROAD, ALTO BETIM, 403521, GOA."
        Phone="+91 9689010199"
        email="pooja.p@expertit.in"
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3844.6034008416755!2d73.82298857489177!3d15.505750685095057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSF-F1%2C%20BETIM%20ROAD%2C%20ALTO%20BETIM%2C%20403521%2C%20GOA.!5e0!3m2!1sen!2sin!4v1711615007320!5m2!1sen!2sin"
      />
    </>
  );

  const MiddalE = (
    <>
      <Contactus2
        city="UAE"
        landmark_address="302, Pluot Building, Dubailand,PO BOX 392380,Dubai,UAE"
        Phone="+97 1563717272"
        email="altaf@expertit.in"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.322152979933!2d55.375922775159914!3d25.090953977779503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f65752f29b8c7%3A0x6423c2b5dd1cbe1!2sDubailand!5e0!3m2!1sen!2sin!4v1711607691934!5m2!1sen!2sin"
      />
    </>
  );

  const Europe = (
    <>
      <Contactus2
        city=""
        landmark_address=""
        Phone=""
        email=""
        src=""
      />
    </>
  );

  return (
    <div className="cont">
      <div className={`popup ${showPopup ? "show" : ""}`}></div>
      <div className="contact-container">
        <h1>How Can I Help You?</h1>
      </div>

      <form className="formaind" action="/action_page.php" data-aos="fade-down">
        <div className="container-yq">
          <div className="headparadiv">
            <h1 className="contactheading">Contact us</h1>
            <p className="contactp">
             
              Contact us about anything related to our company or services.
              We'll do our best to get back to you as soon as possible.
            </p>
          </div>
          <div className="conparadiv">
            <div>
              <div className="rows">
                <div className="rows1">
                  <div className="columnb">
                    <input
                      type="text"
                      placeholder="*First Name"
                      name="name"
                      id="name"
                      required
                      className="input"
                    />
                    <input
                      type="text"
                      placeholder="*Company Name"
                      name="company"
                      id="company"
                      required
                      className="input"
                    />
                    <input
                      type="text"
                      placeholder="*Email"
                      name="email"
                      id="email"
                      required
                      className="input"
                    />
                  </div>
                  <div className="columnb">
                    <input
                      type="text"
                      placeholder="*Last Name"
                      name="last-name"
                      id="last-name"
                      required
                      className="input"
                    />
                    <input
                      type="text"
                      placeholder="*Job Title"
                      name="job-title"
                      id="job-title"
                      required
                      className="input"
                    />
                    <input
                      type="text"
                      placeholder="*Phone Number"
                      name="phone-number"
                      id="phone-number"
                      required
                      className="input"
                    />
                  </div>
                </div>
                <div className="columnb">
                  <textarea
                    cols="40"
                    rows="10"
                    maxLength="1500"
                    className="wpcf7-form-control wpcf7-textarea form-control"
                    id="message-mob"
                    autoComplete="global-your-message-mob"
                    aria-invalid="false"
                    placeholder="How can we help you?"
                    name="global-your-message-mob"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="agree-policy">
              <input
                type="checkbox"
                id="agree-policy1"
                name="agree-policy"
                required
              />
              <label htmlFor="agree-policy1" style={{ paddingLeft: "10px" }}>
                I agree to the privacy policy
              </label>
            </div>
            <div className="agree-policy">
              <input
                type="checkbox"
                id="agree-policy2"
                name="agree-policy"
                required
              />
              <label htmlFor="agree-policy2" style={{ paddingLeft: "10px" }}>
                Subscribe me to Digitec's latest thought leadership, blogs and
                updates.
              </label>
            </div>
            <p className="usemailagree">
              You can also email us directly at{" "}
              <a href="#">support@expertit.in</a>.
            </p>

            <div className="form-groupcaptcha">
              <ReCAPTCHA
                sitekey="6LeM4rApAAAAAJF518kqRD6q1oh9yt79-Pa4aP12"
                onExpired={handleCaptchaExpire}
                onVerify={handleCaptchaVerify}
              />
            </div>
            <div className="grid-container-a">
              <button
                type="submit"
                className="registerbtn"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="mainaddresdiv" data-aos="fade-up">
        <div className="addresdiv">
          <div className="address1outsidediv">
            {" "}
            <h2 className="cantacth2 contacth">Locations</h2>
            <h4 className="conatacth4 contacth">
              Explore our service offerings and subsidiaries in specific
              geography
            </h4>
          </div>
        </div>
        <div className="divforaddress-1">
          <div className="divforaddress">
            {/* america */}
            <div className="americas address1div-np">
              <img
                src="/Images/brandon-mowinkel-DF1hKdVsn_c-unsplash.jpg"
                alt="Americas"
              />
              <p onClick={handalClickamericas}>Americas</p>
              {isOpenamericas && (
                <div className="modal-overlay">
                  <div
                    className="modal-content-m"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div id="close-m" onClick={closeModal}>
                      <i className="fas fa-times closeicon"></i>
                    </div>

                    <div className="modal-np">{americas}</div>
                  </div>
                </div>
              )}
            </div>

            {/* acia */}
            <div className="asia-pacific address1div-np">
              <img src="/Images/images.jpeg" alt="Americas" />
              <p onClick={handalClickAcia}>Asia Pacific</p>
              {isOpenAcia && (
                <div className="modal-overlay">
                  <div
                    className="modal-content-m"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div id="close-m" onClick={closeModal}>
                      <i className="fas fa-times closeicon"></i>
                    </div>

                    <div className="modal-np">{AciaP}</div>
                  </div>
                </div>
              )}
            </div>
            {/* middal-east */}
            <div className="middal-east address1div-np">
              <img src="/Images/ifiltower.jpg" alt="Americas" />
              <p onClick={handalClickmidalAfri}>Middle East & Africa</p>
              {isOpenmidalAfri && (
                <div className="modal-overlay">
                  <div
                    className="modal-content-m"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div id="close-m" onClick={closeModal}>
                      <i className="fas fa-times closeicon"></i>
                    </div>

                    <div className="modal-np">{MiddalE}</div>
                  </div>
                </div>
              )}
            </div>
            {/* europe */}
            <div className=" europe address1div-np">
              <img className="img" src="/Images/europe.jpg" alt="Americas" />
              <p onClick={handalClickEurop}>Europe</p>
              {isOpenEurop && (
                <div className="modal-overlay">
                  <div
                    className="modal-content-m"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <div id="close-m" onClick={closeModal}>
                      <i className="fas fa-times closeicon"></i>
                    </div>

                    <div className="modal-np">{Europe}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
