import React from "react";

import  "./Safe.css";




export default function VM() {

    return (

        <>

       {/* <div><NavBar/></div>  */}

        <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">





        <section id="sml_ht_home_banner">

            <article className="container">

            <div className="row">

              <div className="col-md-9 col-sm-12 col-xs-12 " >

                    <h1 className="h2-heading white-color l-top">

                    Safe Harbor Provision

                    </h1>

                  </div>

            </div>

          </article>

        </section>

       

</div>

        
  <section className="pt75">
    <article className="container">
      <div className="row row-margin">
        
         <div className="col-md-12 col-sm12 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s" style={{marginTop:"0",textAlign:'left'}}>
         <h3>Safe Harbor Provision for www.expertit.in</h3>
         <p style={{}}>Certain statements in this site concerning our future growth prospects, or our future financial or operating performance are forward-looking statements intended to qualify for the 'safe harbor' under the Private Securities Litigation Reform Act of 1995, which involve a number of risks and uncertainties that could cause actual results or outcomes to differ materially from those in such forward-looking statements. The risks and uncertainties relating to these statements include, but are not limited to, risks and uncertainties regarding the execution of our business strategy, our ability to attract and retain personnel, our transition to hybrid work model, economic uncertainties, technological innovations such as Generative AI, the complex and evolving regulatory landscape including immigration regulation changes, our ESG vision, our capital allocation policy and expectations concerning our market position, future operations, margins, profitability, liquidity, capital resources, and our corporate actions including acquisitions. Important factors that may cause actual results or outcomes to differ from those implied by the forward-looking statements are discussed in more detail in our US Securities and Exchange Commission filings including our Annual Report on Form 20-F for the fiscal year ended March 31, 2023. These filings are available at www.sec.gov. sysInfo may, from time to time, make additional written and oral forward-looking statements, including statements contained in the Company's filings with the Securities and Exchange Commission and our reports to shareholders. The Company does not undertake to update any forward-looking statements that may be made from time to time by or on behalf of the Company unless it is required by law.</p>
        </div>
        
        </div>
    
    </article>
  </section>
  
        

        </>
        

    )

  }
