import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";

function ISPMonitoring() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Reduced Downtime and Enhanced Reliability ",
      text: "Maximize uptime and reliability with our ISP monitoring, minimizing downtime and optimizing performance.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Proactive Problem-Solving with AI ",
      text: "AI anticipates and prevents network disruptions, ensuring smooth operations and peak performance.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cost-Effective Network Management ",
      text: "Streamline operations with our cost-effective network management, saving resources without compromising performance.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Data-Driven Insights for Strategic Decisions ",
      text: "Unlock strategic insights with our data-driven analysis, boosting network efficiency and decision-making.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Scalability and Security ",
      text: "Ensure both scalability and security with our comprehensive solution",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np"> </p>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      Offers video conferencing, screen sharing, chat, and more. Email:
      Integrated mail client for handling personal and professional emails.
      <br />
      <br />
      3. Security <br />
      End-to-end encryption.
      <br />
      Two-factor authentication
      <br />
      Built-in ransomware protection.
      <br />
      Machine learning-based suspicious login detection. <br />
      <br />
      4. Apps:
      <br />A large library of apps to enhance functionality, from note-taking
      to music streaming. The SmartCloudApp Store offers various apps to extend
      the platform's capabilities.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">ISP-Monitoring</div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px", fontStyle: "italic" }}
              >
                "Empowering Networks with Advanced AI"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is ISP-Monitoring?</div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              }}
            >
              To the purpose of our system, emphasizing its role in transforming
              telecom and broadband service monitoring through AI.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/ISP.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          {/* <ReadModal2 buttonText="Read More" modalContent={modalContent} /> */}
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                {" "}
                Dynamic IP Address Monitoring
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Explanation of real-time monitoring capabilities.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              GenAI Automatic Troubleshooting
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Detailing the AI-driven diagnostics and resolutions.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Task Logger and Scheduler
            </strong>
            <p class="aligned-paragraph item3text">
              Showcasing AI's efficiency in task management.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Uplink and Downlink Analysis
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Insights into detailed traffic flow analysis.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Real-Time Alerts
            </strong>
            <p class="aligned-paragraph item3text">
              Highlighting the proactive notification system for network
              anomalies.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Interactive Dashboard
            </strong>
            <p class="aligned-paragraph item3text">
              A look at the user-friendly interface for data interpretation.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Integration and API
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Integration with existing network management systems, ticketing
              systems, and other IT tools through APIs for seamless workflow
              automation and data exchange.
            </p>
          </div>
        </div>

        <div class="readmore">
          {/* <button class="read1">Read More</button> */}
        </div>
      </section>

      <div class="section4">
        <div className="sliders">
          {/* Previous and Next buttons */}
          <button
            className="nav-button-slider prev"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            &lt;
          </button>
          <button
            className="nav-button-slider next"
            onClick={() => sliderRef.current?.slickNext()}
          >
            &gt;
          </button>
          {/* Modal */}
          {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

          <div className="slidercontainer">
            <Slider ref={sliderRef} {...settings}>
              {slideData.map((slide) => (
                <div key={slide.id} className="slider-item">
                  <div className="slidecontents">
                    <h2 className="slideheading h2-np slidecontent">
                      {slide.heading}
                    </h2>
                    <p className="slidetext p-np slidecontent">{slide.text}</p>
                    {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
          
          </div>
    
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
    
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
    
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
    
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
    
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}

      {/* section 6 */}

      <section class="section6">
        <div class="section6title">
          <h1 class="section6title-np section6title-np1">Case Studies  </h1>
          <p class="section6title-np2">
            {/* Discover services and offerings that can unlock new possibilities for
            you */}
          </p>
        </div>
        {/* <!-- sectcion6 row --> */}

        <div class="section6row">
          <div class="section61 section666">
            {/* <!-- section6 row1 --> */}
            <div class="_6row1col1 section666col _6text">
              <h1 class="_6textheading">Success Stories from Our Clients</h1>
            </div>

            {/* <!-- row1 col2 --> */}
            <div class="_6row1col2 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np">
                      Featuring a few brief case studies or testimonials that
                      highlight the real-world impact and benefits of your
                      system
                    </a>
                  </li>
                  {/* <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li> */}
                </ul>
              </div>
            </div>
            {/* <!-- row1 col3 --> */}
            {/* <div class="_6row1col3 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div> */}

            {/* <!-- row1 col4 --> */}
            {/* <div class="_6row1col4 section666col">
              <div class="_6text">
                <ul>
                  <li class="a-np">
                    
                      sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution
                    
                  </li>
                  <li class="a-np">
                    
                      sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution
                    
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
            {/* <!-- section6 row2 --> */}
            {/* <div class="section62 section666"> */}
            {/* <!-- row2 col1 --> */}
            {/* <div class="_6row2col1 section666col">
              <div class="_6textheading"><h1>AI Powered Business</h1></div>
            </div> */}
            {/* <!-- row2 col2 --> */}
            {/* <div class="_6row2col2 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <!-- row2 col3 --> */}
            {/* <div class="_6row2col3 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <!-- row2 col4 --> */}
            {/* <div class="_6row2col4 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
            {/* <!-- section6 row3 --> */}
            {/* <div class="section63 section666"> */}
            {/* <!-- row3 col1 --> */}
            {/* <div class="_6row3col1 section666col">
              <div class="_6row3col1 section666col">
                <div class="_6textheading"><h1>AI Powered Business</h1></div>
              </div>
            </div> */}
            {/* <!-- row3 col2 --> */}
            {/* <div class="_6row3col2 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <!-- row3 col3 --> */}
            {/* <div class="_6row3col3 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href=""  class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <!-- row3 col4 --> */}
            {/* <div class="_6row3col4 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ISPMonitoring;
