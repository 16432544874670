import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordians from "./Accordians.js";

function Automativ() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expert System Integration",
      text: " Our deep expertise in integrating complex systems ensures your automotive solutions are seamlessly implemented and fully optimized. ",
      buttonLabel: "Read More",
      modalContent: {
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Innovative AI Applications",
      text: "From predictive analytics to behavior monitoring, our AI-driven solutions push the boundaries of what's possible in automotive manufacturing. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Tailored MES Development",
      text: "Our managed services for MES are fully customizable, ensuring you receive a solution that precisely meets your operational needs. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Cutting-Edge Analytics Platform",
      text: "Syncbridge stands at the forefront of industrial analytics, offering unparalleled insight into your operations. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Commitment to Quality and Efficiency",
      text: "We are dedicated to enhancing both the quality of your products and the efficiency of your processes.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },
  ];

  let items = [
    {
      title:
        "How can your analytics platform Syncbridge enhance our automotive operations?  ",
      content:
        "Syncbridge transforms complex data from your industrial systems into clear, actionable insights, enabling real-time monitoring and strategic decision-making.   ",
    },
    {
      title:
        " What advantages does AI integration bring to our existing automation systems?  ",
      content:
        "AI integration improves efficiency, reduces downtime through predictive maintenance, and enhances overall system intelligence for better performance.     ",
    },
    {
      title:
        "Can SmartVision truly improve the quality of work on the production ramp? ",
      content:
        "Absolutely. By tracking and analyzing staff behavior, SmartVision identifies areas for improvement, ensuring adherence to quality standards and safety protocols.  ",
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">
        {" "}
        The Purpose Behind Our OCR & Object Recognition{" "}
      </p>
      At Expertit Data Informatics, we revolutionize automotive operations by
      integrating cutting-edge technologies with traditional systems. Our
      expertise as system integrators allows us to craft bespoke automotive
      solutions, from dynamic reporting and online analytics with Syncbridge to
      AI-driven enhancements in industrial automation. We're committed to
      elevating quality, efficiency, and innovation in the automotive sector.
      <hr />
      <br /> <strong>Operational Optimization</strong>
      Streamlining automotive processes for efficiency and cost reduction.
      <br />
      <br />
      <strong> Data-Driven Decision-Making</strong>
      Utilizing advanced analytics for informed decision-making in automotive
      operations.
      <br />
      <br />
      <strong>Integration of Advanced Technologies</strong> Incorporating AI and
      machine learning to enhance automation and monitoring.
      <br />
      <br />
      <strong>Quality Monitoring and Assurance</strong> Implementing AI-based
      solutions for consistent product quality in automotive manufacturing.
      <br />
      <br />
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1"> Automotive solutions </div>
                <div
                  className="component-paras"
                  style={{
                    color: "white",
                    marginLeft: "20px",
                    fontStyle: "italic",
                  }}
                >
                  "Driving Innovation – Advanced Automotive Solutions for the
                  Digital Age"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          <div className="cont2-titles"></div>

          <div class="container21">
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  fontSize: "25px",
                  fontFamily: "system-ui",
                }}
              >
                Expertit Data Informatics specializes in providing comprehensive
                automotive solutions, leveraging our deep expertise in system
                integration and advanced technologies. Our services include
                creating insightful reports from Rockwell and Siemens inputs
                displayed on our proprietary online analytics platform,
                Syncbridge. We seamlessly integrate AI-enabled solutions into
                existing industrial automation frameworks, employ Generative AI
                with SmartVision for behavior tracking of ramp staff to monitor
                work quality, and offer managed services for the design and
                development of Manufacturing Execution Systems (MES). Our
                approach ensures automotive operations are not only optimized
                for today but are future-ready.
              </div>
            </div>

            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/loading.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{ paddingLeft: "20px" }}>
                  {" "}
                  Advanced Reporting and Analytics
                </strong>
                <p class="aligned-paragraph item3text">
                  Utilize Syncbridge to transform data from Rockwell and Siemens
                  into actionable insights, enhancing decision-making and
                  operational transparency.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft: "15px",
                  color: "black",
                }}
              >
                AI-Enabled Industrial Automation
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                User is interested in integrating AI for efficiency and
                predictive maintenance.
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class="item32-heading"
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Behavior Tracking with SmartVision
              </strong>
              <p class="aligned-paragraph item3text">
                Implement Generative AI for behavior analysis of ramp staff,
                ensuring high standards of work quality and safety compliance.
              </p>
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                MES System Design and Development
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Offer comprehensive managed services for designing and
                developing tailored MES systems, streamlining manufacturing
                processes and information flows.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Custom Integration Services
              </strong>
              <p class="aligned-paragraph item3text">
                As system integrators, we specialize in customizing solutions to
                fit the unique needs of the automotive industry, ensuring
                seamless integration and operation.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Lifecycle Management Solutions
              </strong>
              <p class="aligned-paragraph item3text">
                Provide lifecycle management solutions for automotive products,
                including product data management, version control, and
                compliance tracking, ensuring regulatory compliance and
                efficient product lifecycle management.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Digital Supply Chain Integration
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Integrate digital supply chain technologies, such as blockchain
                and IoT, to create a transparent and secure supply chain
                ecosystem, enabling traceability, authenticity verification, and
                counterfeit prevention.
              </p>
            </div>
          </div>
        </section>

        <div class="section4">
          <div className="sliders">
            <button
              className="nav-button-slider prev"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              &lt;
            </button>
            <button
              className="nav-button-slider next"
              onClick={() => sliderRef.current?.slickNext()}
            >
              &gt;
            </button>

            <div className="slidercontainer">
              <Slider ref={sliderRef} {...settings}>
                {slideData.map((slide) => (
                  <div key={slide.id} className="slider-item">
                    <div className="slidecontents">
                      <h2 className="slideheading h2-np slidecontent">
                        {slide.heading}
                      </h2>
                      <p className="slidetext p-np slidecontent">
                        {slide.text}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Comprehensive Needs Assessment"
              description=" Starting with a detailed analysis of your current operations and objectives. "
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Custom Solution Design"
              description="  Developing a tailored solution that addresses your specific challenges and integrates seamlessly with your existing systems."
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Implementation and Integration"
              description="Leveraging our system integration expertise to ensure smooth deployment and functionality of your new solutions. "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Continuous Support and Optimization"
              description="Providing ongoing support and optimization services to ensure your solutions continue to deliver maximum value. "
              linkUrl=""
            />
          </div>
        </div>
      </div>

      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default Automativ;
