import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
 
const CookiesBanner = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [showBanner, setShowBanner] = useState(cookies.cookieConsent !== 'true');
 
  const handleAccept = () => {
    setCookie('cookieConsent', 'true', { path: '/' });
    setShowBanner(false);
  };
 
  return (
    <>
      {showBanner && (
        <div className="cookies-banner">
          <p>We use cookies to enhance your experience on our website. By continuing to use this site, you consent to our use of cookies. For more details, please visit our Cookie Policy.</p>
          <button onClick={handleAccept} aria-label="Accept">Accept</button>
        </div>
      )}
    </>
  );
};
 
export default CookiesBanner;