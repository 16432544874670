import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordians from "./Accordians.js";

import SOP from "./SOP.js";

function BuisnessDMS() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Cutting-Edge AI Expertise",
      text: " Our team brings unparalleled expertise in the latest AI technologies, ensuring you stay ahead of the curve.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Tailored AI Solutions",
      text: "  We provide solutions precisely customized to your business needs, ensuring maximum effectiveness and efficiency. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Seamless Integration",
      text: "  Our managed services are designed for smooth integration with your existing workflows, minimizing disruption and maximizing utility.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Focus on Innovation and Growth",
      text: "  By handling the complexities of AI integration and management, we enable you to focus on core business innovation and growth.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Comprehensive Support and Optimization",
      text: " Our ongoing management, support, and optimization services ensure your AI solutions continue to evolve with your business needs.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },
  ];

  let items = [
    {
      title:
        "How do you tailor your digital marketing strategies for different sectors? ",
      content:
        "Our strategies are built on a foundation of industry-specific research and tailored to meet the unique challenges and opportunities of each sector we serve. ",
    },
    {
      title: "What metrics do you focus on to measure success?",
      content:
        "Depending on the sector and campaign goals, we focus on metrics such as web traffic, conversion rates, engagement metrics, lead generation, and ROI. ",
    },
    {
      title:
        "Can you handle the digital marketing needs of a multi-sector business?",
      content:
        "Absolutely. Our expertise across various sectors allows us to devise comprehensive strategies that address the unique needs of multi-sector businesses. ",
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <div className="modal-np">
        <div className="modal-sub-sk">
          <div className="row-sk">
            <div className="card-con-sk">
              <h4>Purpose of the Social Media Services</h4>
              <p>
                At ExpertIt, we leverage the power of digital marketing and
                strategic planning to propel businesses across various sectors
                into the digital future. Our customized solutions are designed
                to enhance visibility, engagement, and conversion across all
                digital platforms, ensuring your brand stands out in today's
                competitive market.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1">
                  {" "}
                  Digital Marketing and Strategy{" "}
                </div>
                <div
                  className="component-paras"
                  style={{
                    color: "white",
                    marginLeft: "20px",
                    fontStyle: "italic",
                  }}
                >
                  "Empowering Innovation, Enhancing Intelligence – Elevate Your
                  Business with AI-Based Managed Services"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          <div className="cont2-titles"></div>

          <div class="container21">
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  fontSize: "25px",
                  fontFamily: "system-ui",
                }}
              >
                In an increasingly digital world, standing out requires not just
                presence, but excellence in execution and strategy.ExperIt
                brings together innovative digital marketing techniques with
                strategic insight across a range of industries, from E-commerce
                and Supply Chain to the Public Sector, Tourism, and Banking. Our
                holistic approach ensures that digital strategies are not only
                aligned with our clients' business goals but also set new
                benchmarks for success in their respective fields.
              </div>
            </div>

            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/gps-benefit.jpg"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        <section class="section3">
          <div class="cont2-title title ">We are Offering</div>

          <div class="section3parts-manageservice">
            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                E-commerce
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Enhance online sales through targeted SEO, SEM, and personalized
                social media campaigns. Leverage data analytics for customer
                insights and tailor your offerings to meet the evolving market
                demands.
              </p>
              <div class="readmore">
                <button class="read1">
                  <Link className="adm-readmore" to="/platforms/ECommerce">
                    {" "}
                    Read more
                  </Link>
                </button>
              </div>
            </div>

            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Supply Chain
              </strong>
              <p class="aligned-paragraph item3text">
                Implement digital marketing strategies to improve supply chain
                visibility, partner engagement, and customer communication,
                ensuring your brand is synonymous with reliability and
                efficiency.
              </p>

              <div class="readmore">
                <button class="read1">
                  <Link className="adm-readmore" to="/businesses/suply">
                    {" "}
                    Read more
                  </Link>
                </button>
              </div>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Public Sector and Tourism
              </strong>
              <p class="aligned-paragraph item3text">
                Utilize digital platforms to promote tourism, engage with the
                community, and enhance public services through effective online
                campaigns, content marketing, and social media outreach.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Banking
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Drive customer acquisition and retention with fintech-focused
                digital marketing strategies. Use data-driven approaches to
                personalize banking services and promote new digital offerings.
              </p>
            </div>
          </div>
        </section>

        <div class="section4">
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t "> USP of Service</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container-sk">
              {/*  */}

              <div className="a-box">
                <div className="img-container">
                  <div className="img-inner">
                    <div className="inner-skew">
                      <img src="/Images/gps-benefit.jpg" alt="A blue bird" />
                    </div>
                  </div>
                </div>
                <div className="text-container">
                  <p className="paraText-h6">
                    Our USP lies in our ability to intersect technology with
                    creative marketing strategies, delivering not just a
                    service, but a transformation. We pride ourselves on our
                    deep industry knowledge, allowing us to customize our
                    digital marketing strategies to your specific challenges and
                    opportunities, ensuring maximum impact and return on
                    investment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="newsection5-np">
          <h1 className="section5heading"> Our SOP for this Service</h1>
          <div className="section5newgrid">
            <SOP
              headding="Discovery and Analysis"
              text=" Understand your market position, goals, and challenges. "
              img="/Images/icons8-requirement-48.png"
            />
            <SOP
              headding="Strategic Planning"
              text="Develop a comprehensive digital marketing strategy tailored to your sector and objectives. "
              img="/Images/test.png"
            />
            <SOP
              headding="Implementation"
              text=" Execute the strategy using a mix of digital marketing tools and platforms, optimized for your target audience. "
              img="/Images/icons8-test-60.png"
            />
            <SOP
              headding="Measurement and Optimization"
              text=" Continuously monitor performance, making data-driven adjustments to strategies and campaigns. "
              img="/Images/report.png"
            />
          </div>
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="E-commerce Launch"
              description="For an emerging online retailer, we executed a multi-channel digital marketing campaign, resulting in a 50% increase in web traffic and a 30% increase in sales within the first quarter. "
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Supply Chain Visibility"
              description=" Implemented a content strategy for a logistics company, enhancing their online presence and establishing them as a thought leader in supply chain solutions, leading to a 20% increase in B2B leads. "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Tourism Engagement"
              description="  For a tourism board, developed a targeted social media campaign that increased website traffic by 40% and significantly boosted online bookings for the season. "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Digital Banking Adoption"
              description="Crafted a digital marketing strategy for a bank to promote its new app, resulting in a 25% increase in app downloads and user engagement within the first two months. "
              linkUrl=""
            />
          </div>
        </div>
      </div>
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for the service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default BuisnessDMS;
