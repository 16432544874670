import React from "react";

import "./Reporting.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBook, faChartLine, faLeaf,faCogs,faFileAlt } from "@fortawesome/free-solid-svg-icons";

export default function VM() {
  return (
    <>
      


<div className="OnlineTP-container">Reporting Analytics Services</div>


<div className="OTP"> Why Analytics</div>



{/* left right conetent for grid left side image right side content start */}

 

{/* conent image 1 */}

<div class="OnlineTP-grid-leftright">

        <div class="OnlineTP-image-left">

        <img src="/Images/reportingas1.jpg"></img>

        </div>


        <div class="OnlineTP-content-right">

 

        <h4 className="OTPh3"> To understand your information needs for your activities</h4>

             
     <p className="OTPp">  Analytics helps fulfill the need for understanding information
                  requirements in various activities by systematically analyzing
                  data, revealing patterns, trends, and correlations. This
                  understanding guides decision-making, strategy development,
                  and resource allocation, enhancing efficiency and
                  effectiveness.</p>

        </div>

    </div>


{/* left right conetent for grid left side image right side content end */}





<section class="cyber-faqs">
          <div class="cyber-container cyber-faqs_container">
            <article className="cyber-faq">
              <div className="cyber-question_answer">


                    
 <ul className="fa-icon-list-horizontal">
   <li>
     <FontAwesomeIcon icon={faSearch} className="section-icon1" />
     Need
   </li>
   <li>
    <FontAwesomeIcon icon={faBook} className="section-icon1" />
    Research
   </li>
  <li>
    <FontAwesomeIcon icon={faChartLine} className="section-icon1" />
    Improve
  </li>
   <li>
    <FontAwesomeIcon icon={faLeaf} className="section-icon1" />
    Grow
  </li>
   
   
 </ul>

               
              </div>
            </article>

           
          </div>
        </section>

 






        <div className="SMV"></div>

        <section className="SMV1">
          <div className="container SMV1_container">
            <div className="SVM2">
              <h4 className="SVM2head">
              What is Reporting
              </h4>
              <p className="SVM2para">
              An effective reporting solution is one of the essential
                  elements for business success. A properly implemented
                  reporting solution provides a competitive advantage, as users
                  have access to the exact information they need exactly when
                  they need it, which accelerates and improves the
                  decision-making process.
              </p>
            </div>
          </div>
        </section>
 

   <div className="SMV"></div>



   <div class="Smart-card-grid">
        {/* <!-- Card 1 --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">What is BO/BI</h4>
            <p class="Smart-card-description">
            BO/BI refers to the tools and processes that organizations
                    use to gather, analyze, and visualize data for informed
                    decision-making and business insights.
            </p>
            
          </div>
        </div>

        {/* <!-- Card 2 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Monitoring</h4>
            <p class="Smart-card-description">
            Monitoring in reporting involves ongoing tracking and
                    assessment of data to ensure performance and compliance.
            </p>
          </div>
        </div>

        {/* <!-- Card 3 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Schedulers</h4>
            <p class="Smart-card-description">
            Schedulers in reporting automate the generation and
                    distribution of reports at predetermined intervals, ensuring
                    timely and consistent delivery of insights to stakeholders.
            </p>
          </div>
        </div>

        {/* <!-- Card 4 (Copy and paste more cards as needed) --> */}
        <div class="Smart-card ">
          <div class="Smart-card-content">
            <h4 class="Smart-card-title">Notifications</h4>
            <p class="Smart-card-description"> Notifications in reporting are real-time alerts that provide
                    stakeholders with timely updates and insights from the data.</p>
          </div>
        </div>
        </div>



        <div className="SMV"> How do we Serve the Need?</div>


        <section class="cyber-faqs">
          <div class="cyber-container cyber-faqs_container">
            <article className="cyber-faq">
              <div className="cyber-question_answer">


                    
 <ul className="fa-icon-list-horizontal">
   <li>
     <FontAwesomeIcon icon={faCogs} className="section-icon1" />
     Define:Define your analytics strategy
   </li>
   <li>
    <FontAwesomeIcon icon={faChartLine} className="section-icon1" />
    Gain:Gain deeper insights from available data
   </li>
  <li>
    <FontAwesomeIcon icon={faBook } className="section-icon1" />
    Understand:Understand reporting requirements
  </li>
   <li>
    <FontAwesomeIcon icon={faFileAlt} className="section-icon1" />
    Publish:Publish Document printing &amp; electronic reporting formats
  </li>
   
   
 </ul>

               
              </div>
            </article>

           
          </div>
        </section>







        {/* new code for image */}

        <div className=" Reporting-clearfix">
        <div
          className="Reporting-promo-video-wrapper bg-sapphire-dark wow fadeInUp animated"
          data-wow-delay="0.9s"
        >
          <div className="Reporting-img-wrapper">
            <img
              data-src="/Images/reportingas2.png"
              className="Reporting-promo-img"
              alt="Ring the Sound of Opportunity, Move 1,000+1,000 Lives Forward"
              src="/Images/reportingas2.png"
            />

           

            <div className="Reporting-image-overlay">
              <button className="Reporting-image-button">Explore on YouTube</button>

              <div className="Reporting-image-text">
                Play the Moment, Replay the Magic
              </div>
            </div>
          </div>
        </div>

      </div> 



        



      {/* 

      <div className="mt50 mb50 clearfix">
        <div
          className="promo-video-wrapper bg-sapphire-dark wow fadeInUp animated"
          data-wow-delay="0.9s"
        >
          <div className="img-wrapper">
            <img
              data-src="https://www.infosys.com/content/dam/infosys-web/en/global-resource/18/banner/launches-new-sonic-identity-lead.png"
              className="promo-img"
              alt="Ring the Sound of Opportunity, Move 1,000+1,000 Lives Forward"
              src="https://www.infosys.com/content/dam/infosys-web/en/global-resource/18/banner/launches-new-sonic-identity-lead.png"
            />

           

            <div className="image-overlay">
              <button className="image-button">Explore on YouTube</button>

              <div className="image-text">
                Play the Moment, Replay the Magic
              </div>
            </div>
          </div>
        </div>

      </div> */}
    </>
  );
}
