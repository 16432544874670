import "./template.css";
 
import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
import Accordians from "./Accordians.js";
// import "./cyberCard.css";
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
 
function NfcService() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },
 
    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },
 
    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },
 
    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 
  let items = [
    {
      title: "How can NFC and FASTag integration benefit my business? ",
      content:
        "Integrating these technologies can significantly enhance customer satisfaction by offering quicker, more convenient payment options, reduce operational costs, and improve security.   ",
    },
    {
      title: "Are NFC and FASTag solutions secure? ",
      content:
        "Absolutely. Both technologies come with advanced security features designed to protect transaction data and prevent fraud. ",
    },
    {
      title: "Can these integrations be customized for any business size? ",
      content:
        "Yes, our solutions are scalable and can be customized to fit the needs of both small businesses and large enterprises.  ",
    },
    
  ];
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener("mousedown", handleOutsideClick);
 
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
 
  const modalContent = (
    <>
      <p className="modal-np">Purpose and Vision of our NFC Services</p>
      
      <hr />

      <div className="modal-sub-sk">
      <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Purpose</h5>
              <p>To revolutionize payment systems by integrating Near Field Communication (NFC) and FASTag technology, facilitating seamless, fast, and secure transactions for businesses and consumers alike.</p>
          </div>
      </div>

      <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Vision</h5>
              <p>To lead the transition to a more connected, efficient, and secure digital payment ecosystem, where transactions are not just transactions but a smooth, frictionless experience.</p>
          </div>
      </div>


      </div>
     
      
 
 
 
 
    
      {/* <br/> <strong>Design, Development, and Implementation</strong>
 
           Custom software solutions tailored to organizational needs enhance operational efficiency and competitiveness through ADM Services.
         <br/><br/>
        <strong>Application Maintenance</strong>
        Ongoing maintenance ensures software functionality, security, and performance, meeting evolving business demands.
 
          <br/><br/><strong>System Integration</strong>
          Seamless communication between systems enhances operational efficiency and decision-making through ADM Services' system integration.
 
          <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );
 
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">NFC Services  </div>
 
              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
              "Your Gateway to Effortless and Secure Transactions"
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>
 
        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}
 
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
 
                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              Tap&Go Solutions provides expert NFC and FASTag integration services, enabling businesses to adopt advanced contactless payment methods. Our service enhances customer convenience, speeds up transaction processes, and fortifies security, aligning with the digital-first approach of modern commerce.
            </div>
          </div>
 
          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/nfcservice.jpg"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>
 
      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>
 
        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
              FASTag Service Integration
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
               Seamlessly integrating FASTag technology for toll collections, parking fees, and fuel payments, reducing wait times and enhancing user convenience. 
              </p>
            </div>
          </div>
 
          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
            NFC Payment Integration
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
            Implementing NFC technology for contactless payment solutions in retail, transportation, and more. 
            </p>
          </div>
 
          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
             Custom Application Development
            </strong>
            <p class="aligned-paragraph item3text">
            Crafting tailored applications that leverage NFC and FASTag for specific business needs. 
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>
 
          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
            Security and Compliance
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Ensuring all integrations meet the highest standards of security and comply with financial regulations. 
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Consultation and Strategy
            </strong>
            <p class="aligned-paragraph item3text">
            Offering expert advice to businesses on how to best implement and benefit from NFC and FASTag technologies.
            </p>
          </div>
 
          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
             Technical Support and Maintenance
            </strong>
            <p class="aligned-paragraph item3text">
            Providing ongoing support to ensure the smooth operation of your contactless payment systems. 
            </p>
          </div>
 
          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
            Contactless Access Control Solutions
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Utilizing NFC technology to implement secure and convenient access control systems for buildings, events, or restricted areas, enhancing security and streamlining entry processes.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>
 
      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}
 
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">USP of NFC Services</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4" >
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Innovative Payment Solutions<FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/global-crisis.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Innovative Payment Solutions</h2>
                      <p>
                      Cutting-edge technology that puts your business ahead in the digital payment space.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Customization and Flexibility
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/laptop.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customization and Flexibility</h2>
                      <p>
                      Tailored solutions that fit your unique business needs and customer expectations.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Expert Team{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/business-network.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expert Team</h2>
                      <p>
                      Our team of experts brings deep knowledge of NFC and FASTag technologies to ensure a successful integration.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
 
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2 className="h2-4-sk">
                        Enhanced Security
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/pattern-lock.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Enhanced Security</h2>
                      <p>
                      Implementing the latest security protocols to protect your transactions and customer data.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              
            
            </div>
          </div>
        </div>
 
        {/* <CarouselSlider/> */}
      </div>
 
      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
         
          </div>
   
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
   
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
   
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
   
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
   
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
        className="newsection5-np"
        // style={{
        //   backgroundImage: `url('/Images/images.jpg')`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   width: "100%",
        //   backgroundColor: "none",
        // }}
      >
        <h1 className="section5heading">Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Initial Consultation"
            text="Understanding your business needs and the scope of integration. "
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Solution Designing"
            text="Crafting a customized plan for NFC and/or FASTag integration.  "
            img="/Images/test.png"
          />
          <SOP
            headding="Implementation and Testing"
            text=" Efficiently integrating the technology into your payment systems with thorough testing for reliability. "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Training and Onboarding"
            text="Providing comprehensive training to your staff on managing and using the new systems. "
            img="/Images/report.png"
          />
          <SOP
            headding="Launch"
            text="Officially launching the integrated systems to your customers with marketing support. "
            img="/Images/planning.png"
          />
          <SOP
            headding="Ongoing Support"
            text="Offering continuous technical support and updates to ensure optimal performance."
            img="/Images/icons8-date-to-48.png"
          />
        </div>
      </div>
      
      <div className="section5">
        <h1 className="section5heading">Use Cases </h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Transforming Urban Mobility"
            description=" NFC Integration for Public Transport "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Retail Reinvented"
            description="Enhancing Customer Experience with Contactless Payments"
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Streamlining Highway Travel"
            description=" FASTag for Toll Management"
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Innovative Hospitality"
            description="Fast, Contactless Payments for Hotels" 
            // linkUrl=""
          />
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
      
      
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>

    </div>
  );
}
 
export default NfcService;