import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import './i18n.js';
import ReactGA from "react-ga4";


ReactGA.initialize("G-H2QZYBYK9R");


ReactGA.send({ hitType: "pageview",
 page: window.location.pathname, });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {/* <Suspense fallback="Loading...">
    <App />
    </Suspense>
    */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
