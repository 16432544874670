import "./template.css";

import React, { useState, useRef, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseStudyCard1 from "./CaseStudyCard1.js";

export default function BankingPlatform() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Masters Module ",
      text: "Advanced SQL editor equipped with AI capabilities for optimizing queries and enhancing data exploration,AI suggestions for query improvement and error minimization. ",
      buttonLabel: "Read More",
      backgroundImage:
        "url('/public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      modalContent: {
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Loan Module ",
      text: "Scalable infrastructure capable of handling large datasets and high user concurrency,Cloud-optimized architecture for flexibility in deployment and scaling. ",
      buttonLabel: "Read More",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Admin Module ",
      text: "Robust security framework with encryption, access controls, and comprehensive audit trails,Ensures data integrity and protection, particularly critical for sensitive CCTV and IoT data. ",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Member Associate Module ",
      text: "Supports a wide range of SQL and NoSQL databases and data warehouses,Facilitates a unified approach to data management across different storage systems. ",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Payment Module ",
      text: "User-friendly interface designed for simplicity and ease of use across all platform functionalities,Streamlines complex operations, making the platform accessible to a diverse user base. ",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    {
      id: 6,
      heading: "General Features ",
      text: "A different text for the second slide.",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 6",
        modalText: "Modal Text 6",
      },
    },

    {
      id: 7,
      heading: "Report Module",
      text: "A different text for the second slide.",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 7",
        modalText: "Modal Text 7",
      },
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Banking platform</div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empowering Data-Driven Decisions"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="section5"
        style={{ backgroundColor: "white", color: "black" }}
      >
        <h1 className="section5heading">Banking Platform</h1>
        <div className="section5jsxcard-3">
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg1.jpg"
            title="CUSTOMER ONBOARDING"
            description="video KYC
            Omnichannel approach"
            linkUrl="/service/BankingPlatform/AI-Transformed-Customer-Journey"
          />
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg2.jpg"
            title="CUSTOMER SERVICES"
            description="OCR Extraction"
            description1="Virtual Chatbot"
            description2="Intuitive user experience"
            description3="Smart notification system"
            linkUrl="/service/BankingPlatform/Platform-And-Benefits"
          />

          <CaseStudyCard1
            imageUrl="/Images/casestudyimg4.jpg"
            title="CUSTOMER NURTURING"
            description="Personalized Offerings"
            description1="Smart Analytics"
            description2="Customizable rules & search engine"
            description3="Role-based Screen access"
          />
        </div>
      </div>
    </div>
  );
}
