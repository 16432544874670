import "./template.css";
 
import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
// import "./cyberCard.css";
import Accordians from "./Accordians.js";
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
 
function FirewallService() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },
 
    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },
 
    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },
 
    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "How can FWaaS be more cost-effective than traditional firewalls? ",
      content:
        "FWaaS eliminates the need for on-site hardware, reducing capital expenditure, and providing a predictable OPEX model with our subscription service.  ",
    },
    {
      title: " Is GuardianWall FWaaS suitable for businesses with strict compliance requirements? ",
      content:
        "Yes, our service includes compliance-ready solutions that adhere to the latest industry standards and regulations.   ",
    },
    {
      title: "How does FWaaS integrate with my existing network infrastructure? ",
      content:
        "Our FWaaS is designed to seamlessly integrate with your current environment, ensuring a smooth transition and immediate protection.   ",
    },
    
    
  ];
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener("mousedown", handleOutsideClick);
 
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
 
  const modalContent = (
    <>
      <p className="modal-np">Purpose and Vision of our Firewall Services</p>
      
      <hr />

      <div className="modal-sub-sk">
      <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Purpose</h5>
              <p>To offer robust, scalable, and cutting-edge Firewall as a Service (FWaaS) solutions that safeguard your business's network infrastructure from evolving threats, ensuring continuous operation and data protection.</p>
          </div>
      </div>

      <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Vision</h5>
              <p>To become the foremost defender of enterprise digital assets, innovating and leading the future of cybersecurity with our comprehensive FWaaS offerings.</p>
          </div>
      </div>


      </div>
     
      
 
 
 
 
    
      {/* <br/> <strong>Design, Development, and Implementation</strong>
 
           Custom software solutions tailored to organizational needs enhance operational efficiency and competitiveness through ADM Services.
         <br/><br/>
        <strong>Application Maintenance</strong>
        Ongoing maintenance ensures software functionality, security, and performance, meeting evolving business demands.
 
          <br/><br/><strong>System Integration</strong>
          Seamless communication between systems enhances operational efficiency and decision-making through ADM Services' system integration.
 
          <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );
 
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Firewall Services  </div>
 
              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
               "Perimeter Security, Redefined" 
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>
 
        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}
 
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
 
                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              GuardianWall FWaaS is a cloud-based security service that provides businesses with the latest firewall protection technology without the need for on-site hardware. Our service delivers a unified threat management system designed to protect your network's perimeter, secure data, and manage traffic based on advanced security protocols.
            </div>
          </div>
 
          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/firewallservice.jpg"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>
 
      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>
 
        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
              Next-Generation Firewall (NGFW)
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                State-of-the-art NGFW capabilities including deep packet inspection, intrusion prevention, and application-level security.
              </p>
            </div>
          </div>
 
          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
             Cloud-Based Management
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
            Simplified and centralized management of firewall settings and policies via a cloud-based dashboard. 
            </p>
          </div>
 
          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Integrated Security Layers
            </strong>
            <p class="aligned-paragraph item3text">
            A cohesive security posture that includes VPN support, anti-malware, URL filtering, and anti-virus protection.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>
 
          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
           Custom Security Policies
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Development and implementation of tailored firewall policies to meet the unique requirements of your business. 
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Scalability
            </strong>
            <p class="aligned-paragraph item3text">
            Easy scaling of security measures to grow with your business needs without the complexity of physical hardware.
            </p>
          </div>
 
          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
             Continuous Updates and Threat Intelligence
            </strong>
            <p class="aligned-paragraph item3text">
            Real-time updates and rich threat intelligence to stay ahead of potential risks. 
            </p>
          </div>
 
          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
             Secure Remote Access
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Securely enable remote work and branch connectivity with robust VPN solutions, ensuring encrypted communication and authentication for remote users and distributed networks.







            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>
 
      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}
 
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">USP of Firewall Services</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4" >
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Simplified Security Operations <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/security.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Simplified Security Operations</h2>
                      <p>
                      Streamlined security management without the complexity of traditional hardware-based solutions. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Continuous Security Assurance
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/shield.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Continuous Security Assurance</h2>
                      <p>
                      Round-the-clock monitoring and immediate response to threats and anomalies.   
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Expert Support{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/social-care.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expert Support</h2>
                      <p>
                      Access to a team of cybersecurity experts dedicated to maintaining and optimizing your firewall protections.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
 
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2 className="h2-4-sk">
                        Flexible and Adaptive
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/loading.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2> Flexible and Adaptive</h2>
                      <p>
                      Our firewall services are designed to adapt quickly to changing threat landscapes and business needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              
            
            </div>
          </div>
        </div>
 
        {/* <CarouselSlider/> */}
      </div>
 
      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
         
          </div>
   
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
   
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
   
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
   
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
   
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
        className="newsection5-np"
        // style={{
        //   backgroundImage: `url('/Images/images.jpg')`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   width: "100%",
        //   backgroundColor: "none",
        // }}
      >
        <h1 className="section5heading">Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Initial Consultation"
            text="Comprehensive assessment of your current network security and understanding of your cybersecurity goals.  "
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Custom Policy Development"
            text="Crafting specific firewall policies that align with your business activities and security requirements.  "
            img="/Images/test.png"
          />
          <SOP
            headding="Seamless Deployment"
            text="Implementing the FWaaS solution with minimal impact on your existing network operations.  "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Ongoing Management"
            text="Providing continuous monitoring, reporting, and adjustments to ensure optimal performance and protection. "
            img="/Images/report.png"
          />
          <SOP
            headding="Regular Compliance Checks"
            text="onducting audits to ensure that firewall configurations comply with industry standards and regulations."
            img="/Images/planning.png"
          />
          <SOP
            headding="Customer Support"
            text="Offering expert support and guidance to resolve issues and answer queries. "
            img="/Images/icons8-date-to-48.png"
          />
        </div>
      </div>
      
      <div className="section5">
        <h1 className="section5heading">Use Cases </h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Safeguarding E-commerce Transactions with Advanced Firewall Protection"
            description="  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Fortifying Healthcare Data with Compliant Firewall Services"
            description=""
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Enhancing Educational Institute Network Security with FWaaS"
            description=" "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Enabling Remote Workforce Connectivity with Secure Firewall Solutions"
            description="" 
            // linkUrl=""
          />
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
      
      
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}
 
export default FirewallService;