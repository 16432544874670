import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordians from "./Accordians.js";
// import "./cyberCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function ClodeDevops() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Customized Cloud Strategies",
      text: " Tailor-made cloud solutions that align with your specific business needs and goals.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Expertise in Cloud Technology",
      text: " A team of experts adept in the latest cloud technologies and best practices. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Proactive Management and Support",
      text: " Ongoing management and proactive support to ensure your cloud environment runs smoothly. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Security and Compliance Focus",
      text: "A strong emphasis on maintaining a secure and compliant cloud environment. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Performance Optimization",
      text: "  Continuous monitoring and optimization to ensure peak performance of your cloud resources. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "What makes cloud migration necessary for modern businesses?",
      content:
        "Cloud migration is key for businesses seeking scalability, flexibility, and efficiency in their operations, allowing for better resource management and cost savings.",
    },
    {
      title:
        "How do you handle data security and privacy during cloud migration?",
      content:
        "We employ rigorous security protocols, including data encryption and compliance checks, to ensure your data's security and privacy during migration and beyond. ",
    },
    {
      title: " Can cloud services be scaled according to business growth?  ",
      content:
        "  Absolutely. Our cloud services are inherently scalable, allowing you to easily adjust resources as your business grows. ",
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <div className="modal-np">
        <div className="modal-sub-sk">
          <div className="row-sk">
            <div className="card-con-sk">
              <h4>Purpose of the Cloud and DevOps Managed Services</h4>
              <p>
                Our Cloud Services are strategically designed to empower your
                organization by simplifying the adoption, management, and
                optimization of cloud technology. We focus on delivering a
                seamless transition to cloud ecosystems, ensuring your business
                harnesses the full potential of cloud computing for increased
                efficiency, scalability, and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">
                Cloud and DevOps Managed Services
              </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empower Your Digital Horizon – Seamless Cloud Integration and
                Management"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              Our Cloud Services encompass a comprehensive suite of solutions
              aimed at the end-to-end implementation and migration of cloud
              ecosystems, coupled with ongoing managed services. This includes
              the planning, execution, and management of cloud infrastructure
              and applications, ensuring they align perfectly with your business
              goals. Our services are tailored to facilitate a smooth transition
              to the cloud, optimizing your resources and operations in a
              secure, scalable environment.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/devops.jpg"
                alt="Description of the image"
                style={{ width: "450px", height: "450px" }}
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Cloud Migration and Implementation
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Expert planning and execution of cloud migration, ensuring
                minimal disruption to your operations.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Cloud Optimization
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Continuous optimization of cloud resources for cost efficiency and
              enhanced performance.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Managed Cloud Infrastructure
            </strong>
            <p class="aligned-paragraph item3text">
              Ongoing management of your cloud infrastructure, including
              monitoring, performance tuning, and security management.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Application Management in the Cloud
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Managing and maintaining cloud-based applications for optimal
              functionality and user experience.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Disaster Recovery and Backup
            </strong>
            <p class="aligned-paragraph item3text">
              mplementing robust disaster recovery and backup strategies for
              business continuity.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Compliance and Security
            </strong>
            <p class="aligned-paragraph item3text">
              Ensuring your cloud environment complies with relevant regulations
              and is fortified with advanced security measures.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Custom Cloud Solutions
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Tailored cloud solutions designed to meet the unique needs of your
              organization.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        <div className="sliders">
          <button
            className="nav-button-slider prev"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            &lt;
          </button>
          <button
            className="nav-button-slider next"
            onClick={() => sliderRef.current?.slickNext()}
          >
            &gt;
          </button>
          {/* Modal */}
          {isModalOpen && selectedSlide && (
            <div className="modal-overlay" ref={modalRef}>
              <div
                className="modal-content-m"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div id="close-m" onClick={closeModal}>
                  <i className="fas fa-times closeicon"></i>
                </div>
                <h2>{selectedSlide.modalContent.modalHeading}</h2>
                <p>{selectedSlide.modalContent.modalText}</p>
              </div>
            </div>
          )}

          <div className="slidercontainer">
            <Slider ref={sliderRef} {...settings}>
              {slideData.map((slide) => (
                <div key={slide.id} className="slider-item">
                  <div className="slidecontents">
                    <h2 className="slideheading h2-np slidecontent">
                      {slide.heading}
                    </h2>
                    <p className="slidetext p-np slidecontent">{slide.text}</p>
                    {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <h3 className="section5punchline">
          Our approach to providing Cloud Services is comprehensive and
          client-centric
        </h3>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Thorough Assessment"
            description="Evaluating your current IT infrastructure and determining the optimal cloud strategy. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Strategic Migration and Implementation"
            description=" Carefully planned and executed migration to the cloud, coupled with end-to-end implementation tailored to your needs. "
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Regular Monitoring and Management"
            description=" Continuous monitoring and management of your cloud infrastructure to ensure optimal performance. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Security and Compliance Checks"
            description="Regular security assessments and compliance checks to protect your data and applications. "
            // linkUrl=""
          />

          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}

          {/* section 6 */}
        </div>
      </div>
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default ClodeDevops;
