import React from "react";
import "./About.css";

export default function Contact() {
  return (
    <div>
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="abt-heading">
              <div className="overlay-sk"></div>
              <h2 className="abt-head-sk">About Us</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="about-content bg-white py-5">
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 order-2 order-lg-1">
              <h2 className="aboutheadings">
                Hindustan Informatics Co. Group Established in 2013​
              </h2>
              <p className="font-italic text-muted mb-4 aboutpara">
                Providing complete Technological Solutions Since 2013 on cutting
                edge technologies.Specialized in Software, Technology and System
                Design. ExpertIT Data Informatics LLP(EIDI) registered in 2020
                under company act 2013,Startup registered to expand scope of few
                areas of technologies from the group.​
              </p>
            </div>
            <div className="col-lg-5 mx-auto order-1 order-lg-2">
              <img
                src="/Images/abt1con.png"
                alt=""
                className="img-fluid mb-4 mb-lg-0"
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 mx-auto">
              <img
                src="https://bootstrapious.com/i/snippets/sn-about/img-2.jpg"
                alt=""
                className="img-fluid mb-4 mb-lg-0"
              />
            </div>
            <div className="col-lg-6">
              <i className="fa fa-leaf fa-2x mb-3 text-primary"></i>
              <h2 className="aboutheadings">
                End-to-End In-house development capability+ Services from
                Fortune 500​
              </h2>
              <p className="font-italic text-muted mb-4">
                Purpose of ExpertIT is majorly to serve in Staff sourcing and IT
                Services section specifically. As a group we design and build
                our own hardware, firmware & software applications around it.
                Our engineering department is continuously building new products
                & working on new ideas to make Industry 4.0 a reality.
              </p>
            </div>

            <div className="col-lg-6 order-2 order-lg-1">
              <h2 className="aboutheadings">
                {" "}
                ERP,SAP,MS Dynamics, Salesforce, DMS, Custom System Development,
                Cloud, desktop Services and Supports ​​
              </h2>
              <p className="font-italic text-muted mb-4 aboutpara">
                Enterprise solution at every level from development, support,
                outsourcing business, we have experience in multiple domains and
                areas like solution architecting, Cloud Support and
                Architecting, Data Center Management​
              </p>
            </div>
            <div className="col-lg-5 mx-auto order-1 order-lg-2">
              <img
                src="https://bootstrapious.com/i/snippets/sn-about/img-1.jpg"
                alt=""
                className="img-fluid mb-4 mb-lg-0"
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 mx-auto">
              <img
                src="https://bootstrapious.com/i/snippets/sn-about/img-2.jpg"
                alt=""
                className="img-fluid mb-4 mb-lg-0"
              />
            </div>
            <div className="col-lg-6">
              <i className="fa fa-leaf fa-2x mb-3 text-primary"></i>
              <h2 className="aboutheadings">
                {" "}
                Advance Product Developments and USPs​
              </h2>
              <p className="font-italic text-muted mb-4">
                AI Enabled Char Bot, Ticketing tools, CCTV BASED AI and Deep
                learning with SMARTVISION, .SAP and Custom ERP integrations
                where we have working machines with skillsets and cases. Other
                Supporting IOT’s like GPS Tracking, RFID's, NFC utilities we use
                on case basis integrated to the system demand.​
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
