import React from 'react'
import "./KPO.css";

function KPO() {
  return (
    <div>
      <div className='KPO'>KPO Services</div>

      <p style={{paddingLeft:'7%'}}>Unlocking Strategic Insights and Efficiency in Accounting, Banking, Freight, and Logistics</p>
   
    <section className="intro">
        <p style={{paddingLeft:'7%'}}>At ExpertIT, we offer specialized Knowledge Process Outsourcing (KPO) services that empower businesses in the fields of Accounting, Banking, Freight, and Logistics. Our dedicated teams of domain experts and analysts are committed to delivering high-value solutions that drive efficiency, accuracy, and strategic insights.</p>
    </section>

    <section class="servicesw-grid" style={{paddingLeft:'7%',marginRight:'20px'}}>
    <div class="servicew">
        <h3>Accounting Services</h3>
        <p>Our accounting KPO services cover a wide spectrum of financial processes:</p>
        <ul>
            <li><strong>Financial Statement Analysis:</strong> Analyze financial statements for informed decision-making.</li>
            <li><strong>Bookkeeping and Record Maintenance:</strong> Ensure accurate and up-to-date financial records.</li>
            <li><strong>Tax Compliance and Planning:</strong> Navigate complex tax regulations while optimizing tax strategies.</li>
            <li><strong>Cost Analysis:</strong> Identify cost-saving opportunities and improve profitability.</li>
        </ul>
    </div>

    <div class="servicew">
        <h3>Banking Services</h3>
        <p>We provide specialized KPO support to banking institutions:</p>
        <ul>
            <li><strong>Mortgage Processing:</strong> Streamline mortgage origination and processing.</li>
            <li><strong>Loan Portfolio Management:</strong> Optimize loan portfolios and mitigate risks.</li>
            <li><strong>Credit Risk Analysis:</strong> Assess creditworthiness and manage credit risk effectively.</li>
            <li><strong>Financial Research and Reporting:</strong> Deliver comprehensive financial reports for informed decisions.</li>
        </ul>
    </div>

    <div class="servicew">
        <h3>Freight and Logistics</h3>
        <p>Our KPO services in freight and logistics enhance operational efficiency:</p>
        <ul>
            <li><strong>Route Optimization:</strong> Optimize shipping routes for cost savings and timely deliveries.</li>
            <li><strong>Inventory Management:</strong> Efficiently manage inventory to reduce carrying costs.</li>
            <li><strong>Supplier Performance Analysis:</strong> Evaluate supplier performance for better sourcing decisions.</li>
            <li><strong>Data Analytics:</strong> Utilize data insights to improve supply chain operations.</li>
        </ul>
    </div>

    <div class="servicew">
        <h3>Benefits of KPO Outsourcing</h3>
        <p>KPO outsourcing with ExpertIT offers several advantages:</p>
        <ul>
            <li><strong>Cost Efficiency:</strong> Reduce operational costs while accessing top-tier talent.</li>
            <li><strong>Focus on Core Activities:</strong> Free up internal resources to concentrate on core business functions.</li>
            <li><strong>Expert Insights:</strong> Gain access to industry experts and data-driven insights.</li>
            <li><strong>Scalability:</strong> Scale services up or down based on your business needs.</li>
            <li><strong>Competitive Advantage:</strong> Stay competitive with accurate data and strategic decision-making.</li>
        </ul>
    </div>
</section>

<div class="containerr" style={{paddingLeft:'7%'}}>
  <section class="team">
    <h2>Our Expert Team</h2>
    <p>Our KPO teams consist of seasoned professionals with deep industry knowledge. We understand the nuances of your specific domain and leverage our expertise to deliver strategic value to your business.</p>
  </section>
 
  <section class="contact" >
    <h2>Contact Us</h2>
    <p>Ready to unlock the potential of KPO services in Accounting, Banking, Freight, and Logistics? Connect with us today to discuss how our specialized solutions can drive your business forward.</p>
    {/* <p>&copy; 2023 [Your Company Name]. All rights reserved.</p> */}
  </section>
  
</div>

<p style={{paddingLeft:'39%'}}>&copy; 2023 ExpertIT. All rights reserved.</p>
    </div>
  )
}

export default KPO
