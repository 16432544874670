import { React, useEffect, useState } from "react";
import "./contactus2.css";
export default function Contactus2({
  city,
  landmark_title,
  landmark_address,
  Phone,
  email,
  email1,
  src,
}) {
  return (
    <div className="contactmaindiv">
      <div className="address1">
        <div className="add-city">
          <h2 className="cityheadding">{city}</h2>
        </div>
        <div className="add-landmark">
          <ul>
            <li className="li-addrestile">
              <h3>{landmark_title}</h3>
            </li>
            <li className="ltext">
              <i class="fas fa-map-marker-alt"></i>
              {landmark_address}
            </li>
            <li className="ltext">
              <i class="fas fa-phone"></i>
              {Phone}
            </li>
            <li className="ltext">
              <i class="fas fa-envelope"></i>
              {email}
            </li>
          </ul>
        </div>

        <div className="ifame">
          <iframe src={src} title="iframe example"></iframe>
        </div>
      </div>
    </div>
  );
}
