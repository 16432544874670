import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
// import "./cyberCard.css";
import Accordians from "./Accordians.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Technical() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: " How do you ensure the quality of outsourced staff?  ",
      content:
        "We have a rigorous selection process, including technical evaluations and soft skill assessments, to ensure we provide personnel who are not only technically proficient but also a cultural fit for your organization.   ",
    },
    {
      title: "Can TechTalent Forge provide staff for short-term projects?  ",
      content:
        "Yes, we offer flexible staffing solutions that cater to both short-term and long-term project needs.  ",
    },
    {
      title: "What makes TechTalent Forge different from other outsourcing services? ",
      content:
        "Our dedication to understanding your specific business challenges allows us to tailor a staffing solution that acts as a true extension of your in-house team.   ",
    },
    
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">
        Purpose and Vision of our Staff Augmentation Services{" "}
      </p>

      <hr />

      <div className="modal-sub-sk">
        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Purpose</h5>
            <p>
              To bridge the gap between innovative companies and exceptional
              technical talent, providing a seamless staff outsourcing service
              that aligns with strategic business goals and project demands.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Vision</h5>
            <p>
              To become the catalyst for technological breakthroughs by
              equipping businesses with a dynamic, skilled workforce that can
              adapt to the evolving tech landscape.
            </p>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Staff Augmentation Services </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Crafting the Team You Need, Powering the Innovation You Seek"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              Tech-Talent Forge is a premier technical staff outsourcing service
              that specializes in supplying businesses with highly qualified
              technical professionals. From software developers to IT project
              managers, we provide the expertise needed to fuel project success
              and drive innovation.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/staff-management.png"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Software Developers and Engineers
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Specialized talent skilled in the latest programming languages
                and development frameworks.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              IT Support and Maintenance Staff
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Reliable professionals to ensure your IT operations run smoothly.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Project Managers
            </strong>
            <p class="aligned-paragraph item3text">
              Expert managers to oversee projects and ensure they are completed
              on time and within budget.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Cybersecurity Experts
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Professionals who can fortify your digital assets against current
              and emerging threats.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Network Engineers
            </strong>
            <p class="aligned-paragraph item3text">
              Skilled personnel to design, implement, and maintain your
              networking infrastructure.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Custom Staffing Solutions
            </strong>
            <p class="aligned-paragraph item3text">
              Flexible staffing options tailored to meet the specific needs of
              your project or business.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Cloud Architects
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Experienced individuals proficient in designing and implementing
              cloud computing solutions to optimize performance, scalability,
              and security while reducing costs.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">
                      USP of Staff Augmentation Services{" "}
                    </h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          High-Caliber Talent Pool{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2> High-Caliber Talent Pool</h2>
                      <p>
                        Access to a vetted, experienced, and diverse range of
                        technical professionals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Flexibility and Scalability
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/business-network.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Flexibility and Scalability</h2>
                      <p>
                        The ability to quickly scale your team up or down as
                        project demands change.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Risk Mitigation{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/approch.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Risk Mitigation</h2>
                      <p>
                        Reducing hiring risks associated with full-time staffing
                        by providing qualified contract professionals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2 className="h2-4-sk">
                          Cost Efficiency
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/pie-chart (1).gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Cost Efficiency</h2>
                      <p>
                        Competitive pricing models that provide financial
                        flexibility and reduce hiring costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      <div
        className="newsection5-np"
        // style={{
        //   backgroundImage: `url('/Images/images.jpg')`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   width: "100%",
        //   backgroundColor: "none",
        // }}
      >
        <h1 className="section5heading"> Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Needs Analysis"
            text="  Understanding the specific skills, experience, and team dynamics you require. "
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Talent Acquisition"
            text="Utilizing our extensive network to identify candidates who match your criteria. "
            img="/Images/test.png"
          />
          <SOP
            headding="Vetting Process"
            text="Conducting thorough interviews, technical tests, and reference checks. "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Onboarding"
            text="Seamlessly integrating outsourced staff into your existing teams and projects. "
            img="/Images/report.png"
          />
          <SOP
            headding="Performance Monitoring"
            text="Regularly evaluating outsourced staff performance to ensure they meet your standards.  give me one more content related to this"
            img="/Images/planning.png"
          />
          <SOP
            headding="Outsourcing Management"
            text=" Establishing effective communication and support structures to enhance collaboration between internal teams and outsourced staff, ensuring productivity and addressing any issues promptly."
            img="/Images/icons8-date-to-48.png"
          />
        </div>
      </div>

      <div className="section5">
        <h1 className="section5heading">Use Cases </h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Accelerating a Fintech Startup with Agile Software Development Talent"
            description="  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Empowering a Telecommunications Giant with Network Engineering Support"
            description=""
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Driving Healthcare Innovation with Top-Tier IT Project Management"
            description=" "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Securing Enterprise Data with Cybersecurity Expertise on Demand"
            description="" 
            // linkUrl=""
          />
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
      
      
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default Technical;
