import "./template.css";
 
import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
import Accordians from "./Accordians.js";
// import "./cyberCard.css";
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
 
function Education() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },
 
    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },
 
    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },
 
    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 
  let items = [
    {
      title: "How do you ensure the security of online examinations? ",
      content:
        "Our platforms use advanced encryption and monitoring tools to uphold examination integrity and security.   ",
    },
    {
      title: "Can your educational animations be customized for specific courses or subjects? ",
      content:
        "Yes, we offer bespoke animation services tailored to the unique requirements of your curriculum.  ",
    },
    {
      title: "What support is available for transitioning to digital learning platforms? ",
      content:
        "We provide comprehensive training, detailed user guides, and ongoing support to ensure a smooth transition.   ",
    },
    
  ];
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener("mousedown", handleOutsideClick);
 
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
 
  const modalContent = (
    <>
      <p className="modal-np">Purpose and Vision of our NFC Services</p>
      
      <hr />

      <div className="modal-sub-sk">
      <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Purpose</h5>
              <p>To revolutionize payment systems by integrating Near Field Communication (NFC) and FASTag technology, facilitating seamless, fast, and secure transactions for businesses and consumers alike.</p>
          </div>
      </div>

      <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Vision</h5>
              <p>To lead the transition to a more connected, efficient, and secure digital payment ecosystem, where transactions are not just transactions but a smooth, frictionless experience.</p>
          </div>
      </div>


      </div>
     
      
 
 
 
 
    
      {/* <br/> <strong>Design, Development, and Implementation</strong>
 
           Custom software solutions tailored to organizational needs enhance operational efficiency and competitiveness through ADM Services.
         <br/><br/>
        <strong>Application Maintenance</strong>
        Ongoing maintenance ensures software functionality, security, and performance, meeting evolving business demands.
 
          <br/><br/><strong>System Integration</strong>
          Seamless communication between systems enhances operational efficiency and decision-making through ADM Services' system integration.
 
          <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );
 
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Educational Services    </div>
 
              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
              "Empowering the Future of Education: Bridging Traditional Learning with Innovative Technologies. "
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>
 
        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}
 
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
 
                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              At ExpertIt, we're dedicated to transforming the educational landscape by integrating cutting-edge technology with traditional learning methodologies. Our comprehensive suite of services is designed to enhance the educational experience at every level, from elementary schools to universities, ensuring students and educators have access to the best tools for success in the digital age. 
            </div>
          </div>
 
          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/EducationalService.png"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>
 
      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>
 
        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
              Education ERP System
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
               Streamline every aspect of school management, from admissions to alumni relations, with our robust ERP solution.
              </p>
            </div>
          </div>
 
          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
            Security and Safety Solutions
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
            Advanced systems including gate automation, zone tracking, and CCTV monitoring to ensure student safety.
            </p>
          </div>
 
          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
             Parental Engagement Tools
            </strong>
            <p class="aligned-paragraph item3text">
            Facilitate real-time communication between schools and parents, keeping families informed and engaged in their child's education.  
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>
 
          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
            Online Tutor Platform
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Supports small tutors and institutions in providing personalized online learning experiences. 
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Online Examination Platforms
            </strong>
            <p class="aligned-paragraph item3text">
            Secure, scalable online exam solutions that maintain integrity and fairness in assessments. 
            </p>
          </div>
 
          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
             E-Learning Resources and Animations
            </strong>
            <p class="aligned-paragraph item3text">
            Engaging educational content and animations that simplify complex concepts and enhance learning outcomes. 
            </p>
          </div>
 
          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
            Virtual Classroom Solutions
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Interactive platforms that replicate the classroom experience, enabling effective remote learning. 
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>
 
      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}
 
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">USP of NFC Services</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4" >
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Customizable and Scalable<FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/laptop.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customizable and Scalable</h2>
                      <p>
                      Our solutions are designed to grow with your institution, ensuring they remain effective no matter how your needs evolve.   
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper ">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Data-Driven Decision Making
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/loading.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Data-Driven Decision Making</h2>
                      <p>
                      Access to analytics and insights to inform educational strategies and improve student outcomes.   
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper ">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Sustainability in Education{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/diagram.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Sustainability in Education</h2>
                      <p>
                      Digital-first solutions that reduce the need for paper, contributing to environmental sustainability. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
 
              
              
              
            
            </div>
          </div>
        </div>
 
        {/* <CarouselSlider/> */}
      </div>
 
      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
         
          </div>
   
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
   
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
   
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
   
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
   
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
        className="newsection5-np"
        // style={{
        //   backgroundImage: `url('/Images/images.jpg')`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   width: "100%",
        //   backgroundColor: "none",
        // }}
      >
        <h1 className="section5heading">Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Comprehensive Needs Analysis"
            text="Working closely with you to understand the specific needs of your institution.  "
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Solution Customization and Integration"
            text="Tailoring our services to fit your requirements and seamlessly integrating them with existing systems.  "
            img="/Images/test.png"
          />
          <SOP
            headding="Training and Support"
            text=" Providing extensive training to educators and administrators, coupled with ongoing technical support. "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Continuous Improvement"
            text="Regularly updating our services based on feedback and emerging educational trends. "
            img="/Images/report.png"
          />
          
        </div>
      </div>
      
      <div className="section5">
        <h1 className="section5heading">Case Studies</h1>
        <div className="section5jsxcard-3">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Transformative Educational Experiences for ABC School"
            description=" ABC School integrated our ERP and safety solutions, resulting in a 50% reduction in administrative workload and a significant enhancement in campus security. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Innovating Learning at XYZ University"
            description="By adopting our online examination platform, XYZ University conducted seamless remote assessments for over 10,000 students, maintaining academic continuity during challenging times."
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Enhancing Understanding with Educational Animations at DEF College"
            description="DEF College utilized our custom animations for their science curriculum, leading to a notable increase in student engagement and comprehension rates. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
      
      
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>

    </div>
  );
}
 
export default Education;