import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Accordians from "./Accordians.js";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function SmartPOS() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise Across Domains",
      text: "Our team brings in-depth knowledge across ADM, ERP, Cloud, and Testing, ensuring a holistic approach to managing your IT landscape. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions",
      text: " We understand that one size does not fit all, offering tailored services that align with your specific business requirements.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Proactive Management",
      text: " Our proactive approach to maintenance and support helps in identifying and resolving issues before they impact your operations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Focus on Innovation",
      text: "While we manage your current technology needs, we also keep an eye on emerging technologies that can further enhance your business operations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Reliable Support",
      text: '  With our round-the-clock support, we ensure your business operations are always running without any hitches.  "',
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "Can SmartPOS adapt to my specific business requirements?   ",
      content:
        "Absolutely. SmartPOS is highly customizable, ensuring it can be tailored to meet the unique demands of your business.  ",
    },
    {
      title: "How does SmartPOS handle inventory management? ",
      content:
        "SmartPOS offers real-time inventory tracking, automatically updating stock levels with every sale or return, and providing valuable insights into inventory needs.    ",
    },
    {
      title: "What support do you offer for accounting and tax filing? ",
      content:
        "Our team provides end-to-end support, from routine bookkeeping to complex tax filing, ensuring your financial operations are accurate, compliant, and optimized for your region's regulations.   ",
    },
    
    
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <div className="modal-np">
        <div className="modal-sub-sk">
          <div className="row-sk">
            <div className="card-con-sk">
              <h4>Purpose of the Smart POS</h4>
              <p>
                Elevate your retail experience with SmartPOS, a system designed
                to streamline operations, enhance customer service, and boost
                sales. Powered by the robust functionality of Odoo POS, SmartPOS
                is more than just a point of sale system; it's a comprehensive
                solution tailored to meet the dynamic needs of modern
                businesses. With ExpertIT, empower your retail operations across
                North America, GCC, South Asia, Africa, and Australia, not only
                with state-of-the-art POS technology but also with expert
                accounting, bookkeeping, and tax filing services.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <hr />
      <div className="modal-sub-sk">
      <div className="row-sk">
        <div className="card-con-sk">
            <h4>Purpose of the Odoo Managed Services</h4>
            <p>Welcome to ExpertIt Data Informatics, your trusted partner in cyber security audits and
      solutions. We offer a comprehensive range of services designed to assess,
      enhance, and protect your organization's digital ecosystem against cyber
      threats and vulnerabilities.</p>
        </div>
    </div>
 
<div className="row-sk">
        <div className="card-con-sk">
            <h4>What is a Frontend Develoment?</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam porro similique aliquid debitis ipsam soluta dolorum xchiszudgv mnviuh cvnkgh nvkdigh cnvilud viud vhg nvuix hvcd m xl ndhg iure.</p>
        </div>
    </div>
 
<div className="row-sk">
        <div className="card-con-sk">
            <h4>What is a Frontend Develoment?</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam porro similique aliquid debitis ipsam soluta dolorum ipsa! Voluptate, suscipit xchiszudgv mnviuh cvnkgh nvkdigh cnvilud viud vhg nvuix hvcd m xl ndhg fdjg vn diux nvidgh  vkgd nvmh iure.</p>
        </div>
    </div>
 
 
 
 
    </div> */}
      {/* <br/> <strong>Design, Development, and Implementation</strong>
 
           Custom software solutions tailored to organizational needs enhance operational efficiency and competitiveness through ADM Services.
         <br/><br/>
        <strong>Application Maintenance</strong>
        Ongoing maintenance ensures software functionality, security, and performance, meeting evolving business demands.
 
          <br/><br/><strong>System Integration</strong>
          Seamless communication between systems enhances operational efficiency and decision-making through ADM Services' system integration.
 
          <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1"> Smart Pharmacy & POS </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Revolutionizing Retail – SmartPOS for Seamless Transactions and
                Beyond"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Managed Services ? </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              SmartPOS, leveraging the powerful features of Odoo POS, offers an
              intuitive, user-friendly, and flexible point of sale solution
              ideal for businesses of any size. It's designed to work both
              online and offline, ensuring reliability and efficiency in all
              sales environments. Beyond processing transactions, SmartPOS
              integrates seamlessly with inventory, accounting, and customer
              relationship management, providing a unified platform for retail
              management.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                style={{
                  width: "100%",
                }}
                class="content2img"
                src="/Images/Pharmacy-Illustration-AI.jpg"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                {" "}
                Seamless Integration
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Fully integrated with Odoo's suite of applications, offering a
                cohesive ecosystem for sales, inventory management, and customer
                relations.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Real-Time Inventory Management
            </strong>
            <div class=" item31-conent-img  "></div>
            <p class="aligned-paragraph item3text " style={{ color: "black" }}>
              Automatic stock adjustments and real-time inventory data ensure
              accurate stock levels and product availability.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Multi-Platform Accessibility
            </strong>
            <p class="aligned-paragraph item3text">
              Operate on various devices, from PCs to tablets, providing
              flexibility and mobility to your sales force.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Customizable Interface
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Tailor the POS interface to fit your business needs, improving
              operational efficiency and user experience.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Loyalty Programs and Promotions
            </strong>
            <p class="aligned-paragraph item3text">
              Easily manage loyalty programs, discounts, and promotions to
              enhance customer retention and sales.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Detailed Analytics and Reporting
            </strong>
            <p class="aligned-paragraph item3text">
              Gain insights into sales trends, customer preferences, and
              inventory needs with comprehensive reporting tools.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Seamless Payment Integration
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              SmartPOS offers seamless integration with multiple payment
              gateways and methods, allowing businesses to accept various
              payment types, including credit/debit cards, mobile payments, and
              digital wallets. This ensures convenience for customers and
              streamlined transaction processing for retailers, ultimately
              enhancing the overall checkout experience.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t "> USP of Smart Pharmacy </h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Comprehensive Retail Solution{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-ico"
                        src="/Images/icons8-retail-50.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Comprehensive Retail Solution</h2>
                      <p>
                        From front-end sales to back-end management, SmartPOS,
                        powered by Odoo, offers a complete solution to
                        revolutionize your retail operations.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Global Financial Expertise{" "}
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/global-crisis.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Global Financial Expertise</h2>
                      <p>
                        Our expertise in accounting, bookkeeping, and tax
                        services across multiple regions ensures your business
                        meets its financial obligations efficiently and
                        accurately.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Customization and Flexibility{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/presentation.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2> Customization and Flexibility</h2>
                      <p>
                        {" "}
                        Tailored solutions that adapt to your business model,
                        offering the flexibility needed to thrive in today's
                        market.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Reliable Support and Training{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/social-care.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Reliable Support and Training</h2>
                      <p>
                        Comprehensive support and training services to maximize
                        the benefits of SmartPOS and ensure your team is
                        well-equipped for success.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Data-Driven Insights
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/statistics.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Data-Driven Insights</h2>
                      <p>
                        Leverage detailed analytics for informed
                        decision-making, helping you understand your business
                        and customers better.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Real time Inventory Management{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/presentation.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2> Real time Inventory Management</h2>
                      <p>
                        SmartPOS offers real-time visibility into inventory
                        levels, allowing retailers to efficiently track stock
                        movements, manage replenishments, and prevent stockouts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
                </div>
            </div>
        </div>
        </div> */}
              {/* <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
              {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
            </div>
          </div>
        </div>

        {/* <div className='sliders'> */}
        {/* Previous and Next buttons */}
        {/* <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

        {/* <CarouselSlider/> */}
      </div>

      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Tailored Implementation"
            description="Deploying SmartPOS solutions customized to your business needs, ensuring a smooth integration with your operations.  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Strategic Financial Services"
            description="Providing strategic advice and support tailored to the financial regulations and standards of your operating regions.  "
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Continuous Support and Optimization"
            description="Offering ongoing assistance and system optimization to ensure your retail and financial operations continue to evolve with your business. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Regional Compliance"
            description=" Ensuring that all accounting, bookkeeping, and tax filing services are compliant with regional regulations, providing peace of mind and security."
            // linkUrl=""
          />

          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}

          {/* section 6 */}
        </div>
      </div>

      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>

    //   </div>
  );
}

export default SmartPOS;
