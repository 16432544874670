import "./template.css";
 
import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
import Accordians from "./Accordians.js";
// import "./cyberCard.css";
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
 
function MonitoringService() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },
 
    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },
 
    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },
 
    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
  
  let items = [
    {
      title: "What types of network components do you monitor? ",
      content:
        "We monitor a wide range of components, including routers, switches, firewalls, servers, and applications to ensure complete network coverage.   ",
    },
    {
      title: "How are alerts managed and escalated?  ",
      content:
        "Alerts are managed according to severity levels and predefined escalation paths to ensure they are addressed promptly and by the appropriate personnel.   ",
    },
    {
      title: "Can NetPulse 360 support multi-vendor environments?  ",
      content:
        "Yes, our service is designed to support and integrate with a broad array of network hardware and software solutions.    ",
    },
   
    
  ];
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener("mousedown", handleOutsideClick);
 
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
 
  const modalContent = (
    <>
      <p className="modal-np">Purpose and Vision of our Monitoring Services</p>
      
      <hr />

      <div className="modal-sub-sk">
      <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Purpose</h5>
              <p>To deliver comprehensive network monitoring services that provide real-time insights into your network's performance, ensuring optimal operation and swift resolution of issues.</p>
          </div>
      </div>

      <div className="row-sk">
          <div className="card-con-sk-4">
              <h5>Vision</h5>
              <p>To empower businesses with unparalleled network visibility and intelligence, enabling proactive management and maintenance for a future where network disruptions are a relic of the past.</p>
          </div>
      </div>


      </div>
     
      
 
 
 
 
    
      {/* <br/> <strong>Design, Development, and Implementation</strong>
 
           Custom software solutions tailored to organizational needs enhance operational efficiency and competitiveness through ADM Services.
         <br/><br/>
        <strong>Application Maintenance</strong>
        Ongoing maintenance ensures software functionality, security, and performance, meeting evolving business demands.
 
          <br/><br/><strong>System Integration</strong>
          Seamless communication between systems enhances operational efficiency and decision-making through ADM Services' system integration.
 
          <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );
 
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Monitoring Services    </div>
 
              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
               "Downtime Is Not an Option" 
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>
 
        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}
 
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
 
                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              NetPulse 360 is a Managed Network Monitoring Service that offers continuous oversight of your IT infrastructure. Our service is engineered to detect, diagnose, and resolve network issues before they escalate, using advanced analytics to maintain the health and efficiency of your network.
            </div>
          </div>
 
          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/monitoringservice.png"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>
 
      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>
 
        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
              24/7 Monitoring
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Around-the-clock surveillance of your network to ensure uptime and quickly identify anomalies. 
              </p>
            </div>
          </div>
 
          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Performance Analysis
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
           Detailed performance metrics to optimize operations and improve response times.  
            </p>
          </div>
 
          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Alerting and Notifications
            </strong>
            <p class="aligned-paragraph item3text">
            Immediate alerts for any network issues, enabling quick action to prevent outages. 
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>
 
          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
           Security Monitoring
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Continuous monitoring for security threats, vulnerabilities, and breaches. 
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Customizable Dashboards
            </strong>
            <p class="aligned-paragraph item3text">
            Tailored views that provide the insights most critical to your business needs. 
            </p>
          </div>
 
          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
             Reporting
            </strong>
            <p class="aligned-paragraph item3text">
            Regular, comprehensive reports that offer insights into network performance, trends, and areas for improvement.
            </p>
          </div>
 
          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
             Compliance Monitoring
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Monitoring and reporting to ensure adherence to regulatory requirements and industry standards, providing peace of mind and avoiding potential fines or penalties.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>
 
      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}
 
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">USP of Monitoring Services</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4" >
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Proactive Approach<FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/interact.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Proactive Approach</h2>
                      <p>
                      Our service anticipates issues before they affect your network, minimizing downtime and operational disruption.   
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Expert Team
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/organigram.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expert Team</h2>
                      <p>
                      Our network operations center is staffed by certified professionals who understand the nuances of network management.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Customized Solutions{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customized Solutions</h2>
                      <p>
                      Services tailored to your specific network setup and business requirements. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
 
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2 className="h2-4-sk">
                        Technology-Driven
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/implementation.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Technology-Driven</h2>
                      <p>
                      Leveraging state-of-the-art tools and technologies for real-time monitoring and analysis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              
            
            </div>
          </div>
        </div>
 
        {/* <CarouselSlider/> */}
      </div>
 
      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
         
          </div>
   
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
   
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
   
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
   
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
   
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
        className="newsection5-np"
        // style={{
        //   backgroundImage: `url('/Images/images.jpg')`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   width: "100%",
        //   backgroundColor: "none",
        // }}
      >
        <h1 className="section5heading">Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Assessment"
            text="Evaluating your existing network infrastructure and monitoring needs.  "
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Implementation"
            text="Setting up our monitoring tools within your network with minimal disruption. "
            img="/Images/test.png"
          />
          <SOP
            headding="Baseline Establishment"
            text=" Determining normal operational thresholds to accurately identify deviations.  "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Active Monitoring"
            text="Continuously tracking network performance and health.  "
            img="/Images/report.png"
          />
          <SOP
            headding="Incident Management"
            text="Efficiently responding to and resolving any identified issues. "
            img="/Images/planning.png"
          />
          <SOP
            headding="Regular Reporting and Review"
            text="Providing detailed reports and reviews to aid in decision-making and strategic planning. "
            img="/Images/icons8-date-to-48.png"
          />
        </div>
      </div>
      
      <div className="section5">
        <h1 className="section5heading">Use Cases </h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Ensuring Uninterrupted Operations for a Global Online Retailer."
            description="  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Optimizing Network Health for a Leading Cloud Service Provider"
            description=""
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Guaranteeing Financial Data Flow for a Secure Banking Network"
            description=" "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Maintaining Peak Performance for a Busy Healthcare IT Infrastructure"
            description="" 
            // linkUrl=""
          />
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
      
      
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}
 
export default MonitoringService;