import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordians from "./Accordians.js";
// import "./cyberCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Accordion from "./Accordians.js";

function DigitalMarketing() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading1: "Commitment to Innovation ",
      heading2: "Future-Ready Strategies",
      text: "We continuously explore and integrate emerging digital marketing trends and technologies. From AI-driven analytics to personalized marketing automation, we ensure your business stays ahead of the curve. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Adaptive Planning",
        modalText:
          "Our strategies are not set in stone. We adapt to the ever-changing digital landscape, ensuring your marketing efforts are always aligned with the latest industry standards and consumer expectations. ",
      },
    },

    {
      id: 3,
      heading1: "Advanced Analytical Approach ",
      heading2: "Data-Driven Insights",
      text: "Leveraging sophisticated analytics tools and AI, we translate complex data into actionable insights. This enables us to refine your marketing strategy continuously for optimal performance. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Predictive Analysis",
        modalText:
          "Utilizing AI for predictive analytics, we can forecast market trends, consumer behavior, and campaign performance. This foresight allows for proactive adjustments to your strategy, maximizing ROI. ",
      },
    },

    {
      id: 4,
      heading1: "Personalized Marketing at Scale ",
      heading2: "Segmentation and Targeting",
      text: "With advanced segmentation tools, we identify and target your ideal customer profiles with precision. This results in higher engagement rates and more effective conversion strategies. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Customized Content Delivery",
        modalText:
          "Utilizing dynamic content personalization techniques, we ensure that your messaging resonates with each segment of your audience on a personal level, significantly enhancing user experience and loyalty. ",
      },
    },

    {
      id: 5,
      heading1: "Innovative Technology Integration ",
      heading2: "AR and VR Experiences",
      text: " We offer augmented reality (AR) and virtual reality (VR) marketing solutions, providing immersive experiences that captivate and engage your audience in unprecedented ways. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Blockchain for Transparency",
        modalText:
          "Implementing blockchain technology, we enhance transparency in advertising, ensuring authenticity and trust in every interaction with your brand. ",
      },
    },

    {
      id: 6,
      heading1: "Expertise in Omnichannel Marketing ",
      heading2: "Seamless Brand Experience",
      text: " Our omnichannel approach ensures a consistent and seamless brand experience across all platforms and devices, enhancing customer satisfaction and loyalty. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Integrated Campaigns",
        modalText:
          "We design campaigns that integrate effortlessly across digital and traditional channels, amplifying reach and impact while maintaining a unified brand voice. ",
      },
    },

    {
      id: 7,
      heading1: "Creative and Strategic Fusio",
      heading2: "Bespoke Strategies",
      text: "Beyond conventional tactics, our team blends creativity with strategic insight, crafting unique and compelling marketing strategies that capture attention and inspire action. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Industry-Specific Expertise",
        modalText:
          " Understanding that each industry has its nuances, we tailor our strategies to meet the specific challenges and opportunities of your market, ensuring relevance and effectiveness. ",
      },
    },

    {
      id: 8,
      heading1: "Comprehensive Support and Collaboration ",
      heading2: "Dedicated Team Collaboration ",
      text: " Our clients benefit from close collaboration with a dedicated team of digital marketing experts, committed to your brand's success and available to pivot strategies as needed.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Continuous Learning and Training",
        modalText:
          "We provide ongoing learning opportunities and training for our clients, ensuring your team remains knowledgeable about the latest digital marketing tools and techniques.",
      },
    },
    // ... (more slides)
  ];

  let items = [
    {
      title: "How do you tailor your strategies to different industries?",
      content:
        "We conduct thorough market research and competitive analysis, leveraging industry-specific trends and insights to craft strategies that resonate with your target market. ",
    },
    {
      title: "How soon can I see results from my digital marketing strategy?",
      content:
        "Results can vary based on numerous factors, including the competitiveness of your industry and the channels used. Some improvements can be seen within 3-6 months, with more significant growth occurring over time. ",
    },
    {
      title:
        "Can you help with both organic and paid digital marketing efforts?",
      content:
        "Yes, our strategic planning covers both organic and paid channels, ensuring a balanced approach to build your online presence sustainably. ",
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <div className="modal-np">
        <div className="modal-sub-sk">
          <div className="row-sk">
            <div className="card-con-sk">
              <h4>Purpose of the Digital Marketing Strategic Planning </h4>
              <p>
                To navigate the complex digital landscape effectively, ensuring
                businesses not only survive but thrive by maximizing their
                online presence, driving growth, and increasing ROI.
              </p>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">
                Digital Marketing Strategic Planning{" "}
              </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empower Your Brand, Drive Results - Master the Digital Game."
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              Digital Marketing Strategic Planning is a comprehensive service
              aimed at creating a tailored, data-driven strategy for businesses
              to optimize their digital marketing efforts. It involves analyzing
              market trends, understanding customer behavior, setting clear
              objectives, and employing the right mix of digital tools and
              channels to enhance online visibility, engagement, and
              conversions. Our approach ensures that your digital marketing
              efforts are aligned with your overall business goals, making every
              click count.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/digitalmarketing.jpg"
                alt="Description of the image"
                style={{}}
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Customized Digital Marketing Strategy
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Development of a unique strategy that aligns with your business
                goals.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Market and Competitive Analysis
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Insights into market trends and competitor strategies to identify
              opportunities.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Audience Segmentation and Targeting
            </strong>
            <p class="aligned-paragraph item3text">
              Identifying and understanding your ideal customers to tailor
              messages that resonate.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Channel Strategy
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Recommendations on the most effective digital platforms (SEO, PPC,
              social media, email marketing, etc.) to reach your target
              audience.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Content Plan
            </strong>
            <p class="aligned-paragraph item3text">
              A strategic content calendar that aligns with your marketing goals
              and engages your audience.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Performance Metrics and KPIs
            </strong>
            <p class="aligned-paragraph item3text">
              Setting up measurable goals and tracking performance to
              continuously optimize the strategy.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Website Design and Development
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Make a lasting impression with a professionally designed website
              optimized for performance and user experience. Whether you need a
              brand-new website or a redesign of your existing site, we create
              visually stunning and functional websites that drive results.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        <div className="sliders">
          {/* Previous and Next buttons */}
          <button
            className="nav-button-slider prev"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            &lt;
          </button>
          <button
            className="nav-button-slider next"
            onClick={() => sliderRef.current?.slickNext()}
          >
            &gt;
          </button>
          {/* Modal */}
          {isModalOpen && selectedSlide && (
            <div className="modal-overlay" ref={modalRef}>
              <div
                className="modal-content-m"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div id="close-m" onClick={closeModal}>
                  <i className="fas fa-times closeicon"></i>
                </div>

                <div className="modal-np">
                  <div className="modal-sub-sk">
                    <div className="row-sk">
                      <div className="card-con-sk">
                        <h4>{selectedSlide.modalContent.modalHeading}</h4>
                        <p>{selectedSlide.modalContent.modalText}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="slidercontainer">
            <Slider ref={sliderRef} {...settings}>
              {slideData.map((slide) => (
                <div key={slide.id} className="slider-item">
                  <div className="slidecontents">
                    <h3
                      className="slideheading h2-np slidecontent"
                      style={{ height: "50%", margin: "5px" }}
                    >
                      {slide.heading1}
                      <p style={{ margin: "5px" }}>{slide.heading2}</p>
                    </h3>

                    <p className="slidetext p-np ">{slide.text}</p>

                    <button
                      className="slidebutton read1"
                      onClick={() => openModal(slide)}
                    >
                      {slide.buttonLabel}
                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      {/* section 6 */}

      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Enquiry and Initial Consultation"
            description="Once we receive your enquiry, we schedule an initial consultation to understand your business, objectives, and challenges. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Reporting and Optimization"
            description=" Regular performance reports are provided, along with strategic insights and recommendations for ongoing optimization."
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Strategy Development"
            description=" Based on our findings, we develop a tailored digital marketing strategy, outlining the channels, tactics, and timeline for implementation.  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Implementation and Monitoring"
            description=" With your approval, we begin the implementation phase, closely monitoring performance and adjusting as needed. "
            // linkUrl=""
          />

          

          {/* section 6 */}
        </div>
      </div>
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default DigitalMarketing;
