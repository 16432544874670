import React from 'react'
import "./template.css"

function SOP( {headding,text, img,...props}) {
  return (
    <div className="newsection5">
      <div className="newsection5grid">
        <div className="newsection5col1">
        <img src={img} alt="Dynamic Image" className="newsection5img"/>
        </div>
        <div className="newsection5col2">
            <h4 className="newsection5headding">
                 {headding}
            </h4>
            <p className="newsection5text">
                {text}
            </p>
        </div>
      </div>
    </div>
  )
}

export default SOP
