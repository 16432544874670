import React from 'react';
import './CaseStudy.css';
import CsForm from './CsForm.js';

const CsCloudCipher1 = () => {
  return (
    <div className="main-casestudydiv">
      <section className="caseStudysection1">
        <div className="casestudytitle">
          <div className="casestudylable">CASE STUDY</div>
          <h1 className="case-headind1">
            {" "}
            CloudCipher Empowering the Revenue and Tax Collection Department
          </h1>
        </div>
      </section>
 
      <div className="part2 col">
        <section className="para-in case-pt75">
          <article className="case-container">
            <div className="row-k">
              <div
                className="row-in case-mb20 fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <p className="part2-para">
                The Revenue and Tax Collection Department of [State/Country] faced challenges in managing vast amounts of sensitive data, ensuring secure access for remote staff, and maintaining compliance with strict government regulations. 
                </p>
                <p className="part2-para">
                CloudCipher was implemented to address these challenges, providing a secure, efficient, and scalable cloud storage and collaboration solution.
                </p>
              </div>
            </div>
            <div className="row-k case-mt45">
              <div className="row-in2 ">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <div className="row1 row-k">
                    <div className="col1">
                      <p>
                        {" "}
                        <strong>Key Challenges</strong>
                      </p>
                      <ul className="case-list-items">
                        <li>
                        Data Security: Sensitive financial data required the highest security standards to prevent breaches and unauthorized access.
                        </li>
                        <li>
                        Remote Access: Field agents and remote staff needed secure and reliable access to databases and documents from various locations.
                        </li>
                        <li>
                        System Integration: Existing tax software needed to be seamlessly integrated with the new cloud storage solution.
                        </li>
                        <li>
                        Compliance and Reporting: The department had to adhere to stringent legal standards for data handling, retention, and reporting.
                        </li>
                      </ul>
                    </div>
                    <div className="col2">
                      <p>
                        {" "}
                        <strong>Ready to experience?</strong>
                      </p>
                      <a
                        href="#rfs"
                        className="scrollto-target pdf"
                        title="Talk To Experts"
                      >
                        TALK TO EXPERTS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div className="part3">
        <section  className="case-pt0 case-pb75">
          <article className="case-container">
            <div className="row-in2">
              <div
                className="row-in2 wow fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <h2 className="h2-heading-cs text-center">The Solution</h2>
                <h3 className=" para-txt col-md-part-2 pd-lr light-gray text-center mb50 ">
                CloudCipher was deployed to overcome these obstacles.
                </h3>
              </div>
              <div
                className="col-part1 wow fadeInLeft animated"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.6s",
                  MozAnimationDelay: "0.6s",
                  animationDelay: "0.6s",
                }}
              >
                <p>
                  <strong>End-to-End Encryption</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Ensuring that all data, in transit and at rest, was encrypted and only accessible to authorized personnel.  
                  </li>
                  
                  
                </ul>
                <p>
                  <strong>Custom Access Controls</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Defining user roles and access levels to ensure staff had access only to the data necessary for their roles.
                  </li>
                  
                </ul>
                <p>
                  <strong>Custom Access Controls</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Defining user roles and access levels to ensure staff had access only to the data necessary for their roles.
                  </li>
                  
                </ul>
                <p>
                  <strong>Automated Compliance Reporting</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                 Implementing tools for generating compliance reports and managing data retention policies.
                  </li>
                  
                </ul>
                
              </div>
            </div>
            <div className="row case-mt65">
              <div className="">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <h2 className="head-case">
                  Implementation
                  </h2>
                  <div className="row case-txt-para">
                    <div className="col1">
                      <ul className="case-list-items">
                        <li>
                        Phase 1: Conducted a thorough assessment of the existing IT infrastructure and compliance requirements.
                        </li>
                        <li>
                        Phase 2: Developed a customized implementation plan for CloudCipher, focusing on minimum disruption to ongoing operations.
                        </li>
                        <li>Phase 3: Rolled out CloudCipher to a pilot group, refining processes based on feedback.</li>
                        <li>Phase 4: Extended deployment across the entire department, with full training and support.</li>
                        <li>Phase 5: Established ongoing evaluation to ensure the system continued to meet the evolving needs of the department.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div className="part4 col">
        <section className="para-in ">
          <article className="case-container">
            
            <div className="row-k ">
              <div className="row-in2 ">
                <div
                  className=" pad-all wow fadeInUp animated">
                  <div className="part4-row1 row-k">
                    
                    <div className="col1">
                    <p>
                  <strong>Results</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Enhanced Security: There were zero breaches or data loss incidents following the implementation of CloudCipher.
                  </li>
                  <li>
                  Increased Efficiency: Field agents reported a 40% improvement in data retrieval and reporting times.
                  </li>
                  <li>
                  Improved Compliance: The department met all compliance requirements in subsequent audits, attributed to CloudCipher's robust reporting tools.
                  </li>
                </ul>
                    </div>
                    <div className="col2">
                    <p>
                  <strong>Conclusion</strong>
                </p>
                <ul className="-list-items">
                  <li>
                  CloudCipher provided the Revenue and Tax Collection Department with a modern, secure, and compliant cloud-based platform that streamlined operations and enhanced data security. 
                  </li>
                  <li>
                  The successful implementation highlighted CloudCipher's potential as an indispensable tool for government agencies looking to modernize their IT infrastructure and improve service delivery
                  </li>
                  <li>
                    Real-time ETA calculation and notifications provided to the
                    parent’s mobile app
                  </li>
                </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
        
      <CsForm />
    </div>
  );
};
 
export default CsCloudCipher1;