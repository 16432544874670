import "./template.css";

import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import CaseStudyCard1 from "./CaseStudyCard1.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function BankingPlatform() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Masters Module ",
      text: "Advanced SQL editor equipped with AI capabilities for optimizing queries and enhancing data exploration,AI suggestions for query improvement and error minimization. ",
      buttonLabel: "Read More",
      backgroundImage:
        "url('/public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      modalContent: {
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Loan Module ",
      text: "Scalable infrastructure capable of handling large datasets and high user concurrency,Cloud-optimized architecture for flexibility in deployment and scaling. ",
      buttonLabel: "Read More",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Admin Module ",
      text: "Robust security framework with encryption, access controls, and comprehensive audit trails,Ensures data integrity and protection, particularly critical for sensitive CCTV and IoT data. ",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Member Associate Module ",
      text: "Supports a wide range of SQL and NoSQL databases and data warehouses,Facilitates a unified approach to data management across different storage systems. ",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Payment Module ",
      text: "User-friendly interface designed for simplicity and ease of use across all platform functionalities,Streamlines complex operations, making the platform accessible to a diverse user base. ",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    {
      id: 6,
      heading: "General Features ",
      text: "A different text for the second slide.",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 6",
        modalText: "Modal Text 6",
      },
    },

    {
      id: 7,
      heading: "Report Module",
      text: "A different text for the second slide.",
      backgroundImage:
        "url('public/Images/pexels-tima-miroshnichenko-5380664.jpg')",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 7",
        modalText: "Modal Text 7",
      },
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Banking platform</div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empowering Data-Driven Decisions"
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section4">
        <div className="sliders">
          <button
            className="nav-button-slider prev "
            style={{ height: "400px" }}
            onClick={() => sliderRef.current?.slickPrev()}
          >
            &lt;
          </button>
          <button
            className="nav-button-slider next"
            style={{ height: "400px" }}
            onClick={() => sliderRef.current?.slickNext()}
          >
            &gt;
          </button>

          <div className="slidercontainer" style={{ height: "400px" }}>
            <Slider ref={sliderRef} {...settings}>
              {slideData.map((slide) => (
                <div
                  key={slide.id}
                  className="slider-item"
                  style={{ height: "400px" }}
                >
                  <div
                    className="slidecontents"
                    style={{
                      height: "300px",
                      backgroundImage: `url(${slide.backgroundImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <h2 className="slideheading h2-np slidecontent">
                      {slide.heading}
                    </h2>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="main-cont">
        <div className="Cardcomp">
          <div className="card-container">
            <div className="row-ca">
              <div className="col-ca">
                <div className="card-head text-center">
                  <h2 className="head-t ">PLATFORMS AI CAPABILITIES</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-container">
          <div className="row-ck">
            <div className="col-ck">
              <div className="card-wrapper">
                <div className="main-card">
                  <div className="innercon">
                    <div className="card-content1">
                      <h2>
                        Data Management <FontAwesomeIcon icon={faArrowRight} />
                      </h2>
                    </div>
                    <img src="/Images/statistics.gif" alt="" />
                  </div>
                </div>
                <div className="hover-card">
                  <div className="hover-content">
                    <h2>Data Management</h2>
                    <p>
                      Secure document storage & customer stamping for future
                      reference Real-time data extraction enabling AI at scale
                      Engineer accurate & actionable insights from a mountain of
                      data
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-ck">
              <div className="card-wrapper">
                <div className="main-card">
                  <div className="innercon">
                    <div className="card-content1">
                      <h2>
                        Predictions
                        <FontAwesomeIcon icon={faArrowRight} />{" "}
                      </h2>
                    </div>
                    <img src="/Images/presentation.png" alt="" />
                  </div>
                </div>
                <div className="hover-card">
                  <div className="hover-content">
                    <h2>Predictions</h2>
                    <p>
                      Predict cash flow user accounts by monitoring
                      transactional behavior Offer best-suited products to your
                      customers based on customer patterns Determine loan amount
                      & period based on AI-generated credit score
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-ck">
              <div className="card-wrapper">
                <div className="main-card">
                  <div className="innercon">
                    <div className="card-content1">
                      <h2>
                        Engage <FontAwesomeIcon icon={faArrowRight} />
                      </h2>
                    </div>
                    <img src="/Images/influence.png" alt="" />
                  </div>
                </div>
                <div className="hover-card">
                  <div className="hover-content">
                    <h2>Engage</h2>
                    <p>
                      Leverage insightful analytics to better serve customers
                      Enable SMS & other notifications to customers Create &
                      execute real-time customer marketing campaigns
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section5">
        <h1 className="section5heading">Banking Platform</h1>
        <div className="section5jsxcard-3">
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg1.jpg"
            title="AI COSTOMER JOURNEY"
            description=""
            linkUrl="/service/BankingPlatform/AI-Transformed-Customer-Journey"
          />
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg2.jpg"
            title="PLATFORMS AND BENIFITS"
            description=""
            linkUrl="/service/BankingPlatform/Platform-And-Benefits"
          />

          <CaseStudyCard1
            imageUrl="/Images/casestudyimg4.jpg"
            title="CORE BANKING AND PACSMODULES"
            description=""
          />
        </div>
      </div>
    </div>
  );
}
