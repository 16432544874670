import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SOP from "./SOP.js";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function HospitalErp() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise Across Domains",
      text: "Our team brings in-depth knowledge across ADM, ERP, Cloud, and Testing, ensuring a holistic approach to managing your IT landscape. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions",
      text: " We understand that one size does not fit all, offering tailored services that align with your specific business requirements.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Proactive Management",
      text: " Our proactive approach to maintenance and support helps in identifying and resolving issues before they impact your operations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Focus on Innovation",
      text: "While we manage your current technology needs, we also keep an eye on emerging technologies that can further enhance your business operations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Reliable Support",
      text: '  With our round-the-clock support, we ensure your business operations are always running without any hitches.  "',
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <div className="modal-np">
        <div className="modal-sub-sk">
          <div className="row-sk">
            <div className="card-con-sk">
              <h4>Purpose of the Hospital ERP Services</h4>
              <p>
                In the rapidly evolving healthcare sector, the need for an
                integrated, efficient hospital management system has never been
                more critical. ExpertIT’s Smart Hospital, powered by Odoo, is
                designed to revolutionize the way hospitals operate, offering
                seamless management of medical, administrative, and financial
                processes. Our comprehensive solution ensures healthcare
                providers can focus on what truly matters – delivering
                exceptional patient care.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1"> Hospital ERP System </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Revolutionizing Healthcare Management – The Smart Hospital
                Solution for Tomorrow’s Healthcare"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Managed Services ? </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              ExpertIT’s Smart Hospital is an innovative, Odoo-based hospital
              management system that offers a holistic approach to managing
              hospital operations. This fully integrated system is tailored to
              meet the complex needs of modern healthcare facilities,
              encompassing everything from patient management to staff
              scheduling, and payroll processing. With a focus on efficiency,
              accuracy, and accessibility, Smart Hospital empowers healthcare
              facilities to optimize their workflows, reduce operational costs,
              and improve patient outcomes.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/hand-drawn-flat-design-erp-illustration_23-2149365029.jpg"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                {" "}
                Patient Management
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Streamline patient registration, appointment scheduling, and
                medical records management with a patient-centric approach.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Staff Shift Management
            </strong>
            <div class=" item31-conent-img  "></div>
            <p class="aligned-paragraph item3text " style={{ color: "black" }}>
              Efficiently manage staff shifts, rosters, and duty schedules to
              ensure optimal staffing levels across all departments.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Payroll Management System
            </strong>
            <p class="aligned-paragraph item3text">
              Automate payroll processing, including salary calculations,
              deductions, and benefits management, ensuring accurate and timely
              staff payments.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Medical Inventory Management
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Maintain optimal levels of medical supplies with real-time
              inventory tracking, automated reordering, and consumption
              tracking.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Appointment and Scheduling
            </strong>
            <p class="aligned-paragraph item3text">
              Enable easy scheduling of patient appointments with doctors and
              medical services, improving accessibility and reducing wait times.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Billing and Invoicing
            </strong>
            <p class="aligned-paragraph item3text">
              Automate billing processes, from consultation fees to medical
              procedures, ensuring accuracy and efficiency in financial
              transactions.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Laboratory and Pharmacy Integration
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Seamlessly integrate laboratory and pharmacy operations, from test
              order management to prescription fulfillment.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t "> USP of Hospital ERP </h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Odoo-Based Integration{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Odoo-Based Integration</h2>
                      <p>
                        Leverage the power and flexibility of Odoo to integrate
                        all aspects of hospital management into a unified
                        platform.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Customizable Solution{" "}
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customizable Solution</h2>
                      <p>
                        Tailor Smart Hospital to fit the unique needs and
                        workflows of your healthcare facility.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Data Security and Compliance{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/pattern-lock.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2> Data Security and Compliance</h2>
                      <p>
                        {" "}
                        Ensure the highest levels of data protection and
                        compliance with healthcare regulations.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Expert Support <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/social-care.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expert Support</h2>
                      <p>
                        Benefit from ExpertIT’s extensive experience in
                        healthcare technology solutions, with dedicated support
                        and training for your staff.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Medical Device Integration
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/medical-insurance.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Medical Device Integration</h2>
                      <p>
                        Integrate medical devices such as monitors, sensors, and
                        imaging equipment with Smart Hospital to automate data
                        capture, improve clinical workflows, and enhance patient
                        monitoring capabilities.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Electronic Health Record (EHR){" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/pro-report.jpg" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2> Electronic Health Record (EHR)</h2>
                      <p>
                        Management Streamline patient data management with
                        robust EHR capabilities within Smart Hospital. Capture,
                        store, and retrieve patient information securely,
                        ensuring healthcare professionals have access to
                        accurate and up-to-date medical records for informed
                        decision-making and efficient patient care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>
      

      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Collaborative Planning"
            description="Work closely with healthcare providers to understand specific needs and customize Smart Hospital accordingly. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Seamless Implementation"
            description="Ensure a smooth transition to Smart Hospital with minimal disruption to hospital operations. "
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Continuous Training and Support"
            description="Provide ongoing training for staff to maximize system benefits and offer dedicated support for any issues. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Regular Updates and Maintenance"
            description="Keep the system up-to-date with the latest features, security measures, and regulatory compliance requirements. "
            // linkUrl=""
          />

          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}

          {/* section 6 */}
        </div>
      </div>
    </div>

    //   </div>
  );
}

export default HospitalErp;
