import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordians from "./Accordians.js";

function ECommerce() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "End-to-End Services",
      text: "From conceptualization to implementation and beyond, we offer comprehensive solutions covering all facets of e-commerce.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Cross-Platform Expertise",
      text: "Our applications are designed to provide seamless experiences across all devices, maximizing user engagement.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Strategic Marketing Edge",
      text: "With our deep understanding of digital and affiliate marketing, we position your brand for maximum visibility and growth. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Customized Solutions",
      text: "We believe in tailor-made solutions that align perfectly with your business goals and market demands. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Reliable Managed Services",
      text: "Our managed services ensure that your e-commerce operations run smoothly, allowing you to focus on strategic business growth. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "How do your e-commerce solutions stand out in the competitive market?",
      content:
        "Our holistic approach, combining cutting-edge technology with strategic marketing and comprehensive managed services, ensures our clients not only launch but also grow and sustain their online presence effectively.   ",
    },
    {
      title: " Can you tailor e-commerce solutions to fit niche markets? ",
      content:
        "Absolutely. Our expertise allows us to customize solutions to cater to specific market segments, ensuring relevance and effectiveness.    ",
    },
    {
      title: "How do you ensure the success of digital marketing strategies?",
      content:
        "By leveraging data analytics and insights, we continuously optimize our strategies to ensure they are targeted, effective, and deliver measurable results.   ",
    },
    
    
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np" style={{ fontSize: "20" }}>
        The Purpose Behind Our E Commerce Solutions
      </p>
      <p className="modal-np">
        {" "}
        At Expertit Data Informatics, we're dedicated to redefining the
        e-commerce landscape with our end-to-end solutions. From cross-platform
        application development to affiliate marketing and digital strategy
        implementation, our services are designed to empower your business,
        ensuring it thrives in the competitive digital marketplace.
      </p>{" "}
      <hr />
      <div className="modal-sub-sk">
        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Global Reach</h5>
            <p>
              E-commerce breaks down geographical barriers, allowing businesses
              to reach customers around the world. This expands market reach and
              opens up new opportunities for growth.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Accessibility</h5>
            <p>
              E-commerce platforms are accessible 24/7, allowing customers to
              shop at any time that is convenient for them. This flexibility
              accommodates varying schedules and time zones.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Cost-effectiveness</h5>
            <p>
              For businesses, e-commerce can be a cost-effective alternative to
              traditional brick-and-mortar stores. It reduces overhead costs
              associated with maintaining physical locations and allows for
              efficient inventory management.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Increased Sales Potential</h5>
            <p>
              E-commerce enables businesses to reach a larger audience and
              target specific customer segments through personalized marketing
              efforts. This can lead to increased sales and revenue.
            </p>
          </div>
        </div>
      </div>
      {/* <br />
      <strong>Student Progress Tracking</strong>
      Online tutor platforms enable tutors to monitor and track students'
      progress and performance, providing valuable insights that can inform
      instructional strategies and interventions.
      <br />
      <br />
      <strong>Efficient Course Management</strong>
      By centralizing course materials, communication channels, and
      administrative functions, online tutor platforms streamline course
      management tasks, saving tutors time and effort.
      <br />
      <br />
      <strong>Enhanced Digital Presence</strong> For tutors looking to establish
      or enhance their digital presence, online tutor platforms offer a
      professional platform to showcase their expertise and attract students.
      <br />
      <br /> <strong>Maximized Teaching Impact</strong> Ultimately, the purpose
      of an online tutor platform is to maximize the teaching impact of tutors
      by providing them with the tools and support they need to deliver
      high-quality instruction in a secure and effective online environment. */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1">E Commerce Solutions </div>
                <div
                  className="component-paras"
                  style={{
                    color: "white",
                    marginLeft: "20px",
                    fontStyle: "italic",
                  }}
                >
                  "Transforming Digital Commerce – Comprehensive E-commerce
                  Solutions for Tomorrow's Market"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is E Commerce Solutions?</div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                  fontSize: "25px",
                  fontFamily: "system-ui",
                }}
              >
                Expertit Data Informatics offers a wide range of E-commerce
                Solutions tailored to meet the dynamic needs of the digital
                commerce industry. Our expertise spans across creating robust
                e-commerce applications, executing impactful affiliate and
                digital marketing strategies, and providing managed services to
                ensure seamless operation. Leveraging the latest technologies
                and marketing insights, we help businesses of all sizes to
                build, grow, and sustain a successful online presence.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/schoolerp-2.png"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{ paddingLeft: "20px" }}>
                  Cross-Platform E-commerce Application Development
                </strong>
                <p class="aligned-paragraph item3text">
                  Developing responsive, user-friendly e-commerce applications
                  accessible across various devices and platforms.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft: "15px",
                  color: "black",
                }}
              >
                Affiliate Marketing Solutions
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Crafting and managing affiliate programs to expand market reach
                and drive sales through strategic partnerships.
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Analytics and Insight
              </strong>
              <p class="aligned-paragraph item3text">
                Utilizing advanced analytics to gather insights, optimize
                performance, and drive informed decision-making.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Managed Services
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Offering a suite of managed services covering every aspect of
                the e-commerce industry, from inventory management to customer
                support, ensuring smooth operations.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Digital Marketing and Strategy Implementation
              </strong>
              <p class="aligned-paragraph item3text">
                Implementing comprehensive digital marketing strategies,
                including SEO, SEM, content marketing, and social media
                marketing to enhance online visibility and engagement.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Customization and Integration
              </strong>
              <p class="aligned-paragraph item3text">
                Tailoring solutions to fit your specific business model and
                seamlessly integrating with existing systems for enhanced
                functionality.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Customer Relationship Management
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Implementing CRM systems to manage customer interactions, track
                leads, and streamline communication processes, fostering
                stronger customer relationships and improving retention rates.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          <div className="sliders">
            {/* Previous and Next buttons */}
            <button
              className="nav-button-slider prev"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              &lt;
            </button>
            <button
              className="nav-button-slider next"
              onClick={() => sliderRef.current?.slickNext()}
            >
              &gt;
            </button>
            {/* Modal */}
            {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

            <div className="slidercontainer">
              <Slider ref={sliderRef} {...settings}>
                {slideData.map((slide) => (
                  <div key={slide.id} className="slider-item">
                    <div className="slidecontents">
                      <h2 className="slideheading h2-np slidecontent">
                        {slide.heading}
                      </h2>
                      <p className="slidetext p-np slidecontent">
                        {slide.text}
                      </p>
                      {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* <CarouselSlider/> */}
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Solution Design and Implementation"
              description="Utilizing the latest technologies, we design and implement robust e-commerce solutions. "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Consultation and Strategy Development"
              description="Starting with a deep-dive analysis to understand your business, we develop a tailored e-commerce strategy."
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Marketing Strategy Execution"
              description="We deploy comprehensive marketing strategies, continuously optimized based on performance data. "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Ongoing Support and Optimization"
              description="Providing continuous support and optimization services to ensure peak performance and adaptability to market changes."
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>
      </div>

      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default ECommerce;
