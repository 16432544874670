import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
// import "./cyberCard.css";
import Accordians from "./Accordians.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function InformationService() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "How do you ensure the security of our data? ",
      content:
        "We employ state-of-the-art security measures and comply with industry-standard protocols to protect your data.   ",
    },
    {
      title: "Can your services scale as our business grows?    ",
      content:
        "Absolutely. Our solutions are designed to be scalable, ensuring they evolve in line with your business.  ",
    },
    
    {
        title: "What makes ExpertIt different from other information services providers? ",
        content:
          "Our unique blend of expertise, technology, and a client-centric approach sets us apart, ensuring we deliver unmatched value and results.  ",
      },
    
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <div className="modal-np">
      <div className="modal-sub-sk">
      <div className="row-sk">
        <div className="card-con-sk">
            <h4>Purpose of the Information Services</h4>
            <p>At ExpertIt, we understand that information is your most valuable asset. Our expertise lies in our ability to transform vast amounts of data into actionable insights, empowering your business to achieve its objectives with precision and agility. Through innovative approaches and cutting-edge technologies, we deliver tailored solutions that optimize your information management processes and propel your business forward.  </p>
        </div>
    </div>
    </div>
       
         </div>
      
 
    </>
  );
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Information Services  </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                {/* "Crafting the Team You Need, Powering the Innovation You Seek" */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
             In the fast-paced world of digital transformation, ExpertIt stands as a beacon for businesses seeking to navigate the complexities of information management and leverage data for strategic advantage. Our Information Services Business is dedicated to unlocking the power of information, providing our clients with the insights needed to make informed decisions, drive growth, and maintain competitive edge. 
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/infoservice.jpg"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Services</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
              Data Management and Analytics
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Harness the full potential of your data with our comprehensive management and analytics services. From data warehousing and integration to advanced analytics and visualization, we turn data into insights. 
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
             Business Intelligence
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
            Elevate your strategic planning with our business intelligence solutions. We provide you with the tools and insights necessary to understand market trends, customer behavior, and operational efficiency. 
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
             Content Management and Strategy
            </strong>
            <p class="aligned-paragraph item3text">
            Organize, store, and retrieve your content effectively with our content management solutions. Our strategies ensure that your information is secure, accessible, and aligned with your business goals.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
          Compliance and Risk Management
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Navigate the complex landscape of compliance with our expert guidance. We help you manage risks associated with information management, ensuring that your business adheres to relevant laws and regulations. 
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Consulting and Advisory Services
            </strong>
            <p class="aligned-paragraph item3text">
            Benefit from our deep industry knowledge and expertise. Our consultants provide tailored advice to optimize your information management strategy and technology investments. 
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
            Digital Transformation
            </strong>
            <p class="aligned-paragraph item3text">
            Modernize your business with tailored solutions, from digitizing workflows to implementing cutting-edge tech, empowering adaptation and innovation.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
             Customer Experience
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
            Boost satisfaction and loyalty with personalized solutions leveraging data analytics, anticipating needs, and delivering seamless experiences across touchpoints.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

<div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">USP of Information Services</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4" >
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Customized Solutions <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customized Solutions</h2>
                      <p>
                      Tailored services that align with your specific business needs and objectives. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Expertise and Experience
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/organigram.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expertise and Experience</h2>
                      <p>
                      A team of seasoned professionals with deep industry knowledge and a proven track record of success.   
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                        Technology-Driven{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/implementation.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Technology-Driven</h2>
                      <p>
                      Utilization of the latest technologies and methodologies to deliver efficient and effective solutions.  
                      </p>
                    </div>
                  </div>
                </div>
              </div>
 
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2 className="h2-4-sk">
                        Client-Centric Approach
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/loading.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2> Client-Centric Approach</h2>
                      <p>
                      A commitment to understanding and addressing the unique challenges and opportunities of each client. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              
            
            </div>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      <div
        className="newsection5-np"
        // style={{
        //   backgroundImage: `url('/Images/images.jpg')`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   width: "100%",
        //   backgroundColor: "none",
        // }}
      >
        <h1 className="section5heading"> Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Consultation"
            text="   Understanding your business, challenges, and objectives.  "
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Solution Design"
            text="Crafting a tailored strategy and solution based on our findings. "
            img="/Images/test.png"
          />
          <SOP
            headding="Implementation"
            text="Deploying the solution with precision and care.   "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Support and Optimization"
            text="Providing ongoing support and continuously optimizing the solution for maximum impact "
            img="/Images/report.png"
          />
          
         
        </div>
      </div>

      
      
      
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default InformationService;
