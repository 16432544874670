import React from "react";
import { Link } from "react-router-dom";
import "./ProductModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faMicrochip,
  faShoppingCart,
  faLightbulb,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";

export default function () {
  return (
    <>
      <div className="col-md-12 col-sm-12 col-xs-12">
        <h2 className="submenu-h2-heading ">Platforms</h2>
      </div>
      <div className="card-Product">
        <div className="Product-Product">
          <div className="service-row">
            <div className="service-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faCogs} className="section-icon" />

                <div className="heading-h6">Expert IT Owned</div>
              </div>
            </div>

            <div className="service-column">
              <ul className="list-inline mb0">
                
                <li className="Product-list-item">
                  <Link to="/platforms/CloudCipher">Cloud Cipher</Link>
                </li>

                <li className="Product-list-item">
                  <Link to="/platforms/Online">Online Tutor Platform</Link>
                </li>

                <li className="Product-list-item">
                  <Link to="/platforms/OnlineAnalytics">Online Analytics</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/platforms/BankingPlatform">Banking Platfrom</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/platforms/VoIP">VoIP platform</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/platforms/ECommerce">E commerce platform</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>

          {/* ERP */}

          <div className="Product-row" style={{ justifyContent: "flex-start" }}>
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faBriefcase} className="Product-icon" />

                <div className="heading-h6">ERP</div>
              </div>
            </div>

            <div className="Product-column">
              <ul className="list-inline">
                <li className="Product-list-item">
                  <Link to="/platforms/sap">SAP</Link>
                </li>

                <li className="Product-list-item">
                  <Link to="/platforms/msdynamic">MS Dynamics</Link>
                </li>

                <li className="Product-list-item">
                  <Link to="/platforms/odooservice">Odoo</Link>
                </li>


                <li className="Product-list-item">
                  <Link to="/platforms/hospital-ERP">Hospital ERP Systems</Link>
                </li>

              

                
                <li className="Product-list-item">
                  <Link to="/platforms/smartpos">
                    Smart Pharmacy & POS
                  </Link>
                </li>

                <li className="Product-list-item">
                  <Link to="/platforms/schoolerp">
                    School ERP & Automation
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>

          {/* IOT */}

       
         

          {/* AI/MI */}

          <div className="Product-row" style={{ justifyContent: "flex-start" }}>
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faLightbulb} className="Product-icon" />

                <div className="heading-h6">
                  Artificial Intelligence/Machine Learning (AI/ML)
                </div>
              </div>
            </div>

            <div className="Product-column">
              <ul className="list-inline ">
                {/* <li className="Product-list-item">
                <Link to="/Products/Integration with Machine (INDUSTRY 4.0).html">Integration with Machine (INDUSTRY 4.0)</Link>
              </li> */}

                <li className="Product-list-item">
                  <Link to="/platforms/AISmart">AI Smart Vision</Link>
                </li>

                <li className="Product-list-item">
                  <Link to="/platforms/genai">
                    Generative AI
                    
                  </Link>
                  </li>
              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>

        
        </div>
      </div>
    </>
  );
}
