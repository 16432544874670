import "./template.css";

import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
// import "./cyberCard.css";
import Accordians from "./Accordians.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function AccountingFinance() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },
  ];

  let items = [
    {
      title:
        "How does outsourcing finance and accounting services benefit my business?  ",
      content:
        " It allows you to focus on core business activities while we take care of the financial details, reduce operating costs, and gain access to expert knowledge and advanced technologies.    ",
    },
    {
      title: "Can LedgerLogics integrate with our existing financial systems? ",
      content:
        "Absolutely, we pride ourselves on our ability to adapt and work within our clients' established systems for a seamless transition. ",
    },
    {
      title: "What measures do you take to ensure data security?  ",
      content:
        "We employ strict security protocols, including encryption and access controls, to ensure the confidentiality and integrity of your financial data.   ",
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">
        Purpose and Vision of our Accounting and Finance Services{" "}
      </p>

      <hr />

      <div className="modal-sub-sk">
        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Purpose</h5>
            <p>
              To provide comprehensive accounting and finance services
              outsourcing, delivering precision, compliance, and strategic
              insight to businesses of all sizes.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Vision</h5>
            <p>
              To redefine financial services outsourcing by becoming an integral
              part of our clients’ success, enabling them to focus on growth
              while we ensure financial accuracy and efficiency.
            </p>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">
                Accounting and Finance Services{" "}
              </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Balancing Your Books, Empowering Your Business"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        <div className="cont2-titles"></div>

        <div class="container21">
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              Ledger Logics offers a full suite of outsourced accounting and
              finance services, from day-to-day bookkeeping to sophisticated
              financial analysis and reporting. Our skilled financial experts
              become an extension of your team, working diligently to streamline
              your financial operations.
            </div>
          </div>

          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/analytics.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>Book keeping</strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Meticulous record-keeping and transaction management to keep
                your ledgers current and accurate.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Financial Reporting
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Comprehensive reporting, including balance sheets, income
              statements, and cash flow analysis, tailored to stakeholder
              specifications.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Accounts Payable and Receivable Management
            </strong>
            <p class="aligned-paragraph item3text">
              Efficient handling of invoicing and bill payments to optimize your
              cash flow.
            </p>
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Tax Preparation and Compliance
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Ensuring accurate and timely tax filings and adherence to
              regulations to avoid penalties.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Payroll Processing
            </strong>
            <p class="aligned-paragraph item3text">
              Reliable payroll management that ensures employees are paid
              correctly and on time.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Financial Planning and Analysis
            </strong>
            <p class="aligned-paragraph item3text">
              Strategic financial insights to aid in decision-making and
              long-term planning.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Financial Forecasting
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Utilizing historical data and future projections to create precise
              budgets, anticipate challenges, and identify growth opportunities,
              ensuring financial stability and informed decision-making.
            </p>
          </div>
        </div>
      </section>

      <div class="section4">
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">
                      USP of Mobile Application Testing{" "}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Expertise and Experience{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                      </div>
                      <img
                        className="img-4"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expertise and Experience</h2>
                      <p>
                        A team of certified accountants and financial
                        professionals with extensive industry knowledge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Customized Service Models
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                      </div>
                      <img className="img-4" src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customized Service Models</h2>
                      <p>
                        Tailored solutions designed to fit the unique needs of
                        your business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Technology-Driven Processes{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                      </div>
                      <img className="img-4" src="/Images/approch.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Technology-Driven Processes</h2>
                      <p>
                        Utilization of the latest accounting software and tools
                        for efficient service delivery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2 className="h2-4-sk">
                          Data Security
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                      </div>
                      <img className="img-4" src="/Images/padlock.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Data Security</h2>
                      <p>
                        Commitment to the highest levels of data security and
                        privacy standards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="newsection5-np">
        <h1 className="section5heading"> Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Client Onboarding"
            text="  A thorough assessment of your current financial processes and understanding of your business objectives. "
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Service Customization"
            text="Developing a service plan that aligns with your specific accounting and financial needs. "
            img="/Images/test.png"
          />
          <SOP
            headding="System Integration"
            text="Seamless integration with your existing financial systems for data accuracy and consistency. "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Operational Execution"
            text="Execution of day-to-day accounting and financial tasks with precision and professionalism. "
            img="/Images/report.png"
          />
          <SOP
            headding="Compliance Monitoring"
            text="Regular checks to ensure all financial operations comply with applicable laws and regulations. "
            img="/Images/planning.png"
          />
          <SOP
            headding="Reporting and Insights"
            text="Delivery of timely, accurate financial reports and valuable insights for your business decisions. "
            img="/Images/icons8-date-to-48.png"
          />
        </div>
      </div>

      <div className="section5">
        <h1 className="section5heading">Use Cases </h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Streamlining Finances for a High-Growth Tech Startup"
            description="  "
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Modernizing Accounting Practices for a Non-Profit Organization"
            description=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Financial Restructuring for a Manufacturing SME"
            description=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Tax Efficiency and Compliance for a Retail Chain"
            description=""
          />
        </div>
      </div>

      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default AccountingFinance;
