import "./template.css";
import { Link } from "react-router-dom";
import CarouselSlider from "./Carousel.js";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseStudyCard from "./CaseStudyCard.js";

function AISmart() {
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Leading-Edge Technology",
      text: "SmartVision is built with the latest in camera and AI technology, offering unparalleled monitoring and analytics capabilities.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 1",
        modalText: "Modal Text 1",
      },
    },
    {
      id: 2,
      heading: "Adaptable and Scalable",
      text: "Whether it’s for small-scale operations or large-scale surveillance, SmartVision is designed to scale and adapt to your specific needs. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Enhanced Security and Efficiency",
      text: "Provides enhanced security features while streamlining operational processes through intelligent automation.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Diverse Application",
      text: "Ideal for a range of applications, from business security to traffic monitoring and beyond. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Continuous Innovation and Support",
      text: "We continually update and support SmartVision, ensuring it stays at the cutting edge of surveillance technology. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np" style={{ fontSize: "20px" }}>
        The Purpose Behind Our Smartvision Services{" "}
      </p>
      <p className="modal-np">
        {" "}
        At Expertit SmartVision is engineered to redefine monitoring solutions,
        blending advanced CCTV technology with AI-powered analytics. This
        platform is not just about surveillance; it’s about empowering
        businesses and communities with smart, automated, and insightful
        monitoring capabilities.{" "}
      </p>{" "}
      <hr />
      <div className="modal-sub-sk">
        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Enhanced Surveillance and Security</h5>
            <p>
              SmartVision systems can monitor environments in real-time, detect
              anomalies, and provide alerts for potential security threats.
              These systems can be deployed in public spaces, transportation
              hubs, critical infrastructure, and smart cities to enhance safety
              and security.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Automated Manufacturing and Quality Control</h5>
            <p>
              In industrial settings, SmartVision solutions automate inspection
              processes by analyzing visual data to identify defects, ensure
              product quality, and optimize production efficiency. This can lead
              to reduced costs, improved product consistency, and increased
              throughput.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Smart Retail and Marketing</h5>
            <p>
              SmartVision technology enables retailers to gather insights into
              customer behavior, preferences, and demographics by analyzing
              video feeds from cameras installed in stores. This data can be
              used to personalize marketing efforts, optimize store layouts, and
              improve the overall shopping experience.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Traffic Management and Transportation</h5>
            <p>
              SmartVision systems can monitor traffic flow, detect congestion,
              and manage traffic signals to optimize traffic flow in urban
              areas. Additionally, these systems can assist with automated
              vehicle guidance, parking management, and pedestrian safety.
            </p>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">AI Smartvision</div>
              <div
                className="component-paras"
                style={{
                  color: "white",
                  marginleft: "20px",
                  fontStyle: "italic",
                }}
              >
                "Envision Safety and Efficiency – The Future of Monitoring with
                SmartVision"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        <div className="cont2-titles"></div>

        <div class="container21">
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                fontFamily: "system-ui",
              }}
            >
              SmartVision is an innovative fusion of high-quality camera systems
              and cutting-edge AI software, designed to offer unparalleled
              monitoring, alerting, and environmental control capabilities.
              Tailored for diverse environments, SmartVision represents the next
              leap in intelligent surveillance technology.
            </div>
          </div>

          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/AI.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>

        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Intelligent Camera System
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Incorporates a set of certified cameras with IoT connectivity
                and essential sensors, ensuring comprehensive monitoring
                capabilities.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              User-Friendly Interface
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-1"
              style={{ color: "black" }}
            >
              Designed with intuitive interfaces, making it accessible and easy
              to use across multiple devices.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
              }}
            >
              Cross-Platform Accessibility
            </strong>
            <p class="aligned-paragraph item3text">
              Ensures easy and seamless access via mobile phones, websites,
              SmartTVs, and smartwatches, enhancing user convenience and
              operational flexibility.
            </p>
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              AI-Enabled SaaS Platform
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Offers advanced features such as event logging, broadcasting, and
              commanding, along with user-friendly templates tailored for
              diverse use cases.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Advanced Integration
            </strong>
            <p class="aligned-paragraph item3text">
              Uniquely combines camera hardware with AI-driven software, setting
              a new standard in intelligent surveillance.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Customization
            </strong>
            <p class="aligned-paragraph item3text">
              Highly adaptable to a wide range of environments and use cases,
              ensuring versatility and applicability across industries.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Customizable and Scalable
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Adaptable to a wide range of environments, from residential to
              industrial, ensuring scalability as your needs evolve.
            </p>
          </div>
        </div>
      </section>

      <div class="section4">
        <div className="sliders">
          <button
            className="nav-button-slider prev"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            &lt;
          </button>
          <button
            className="nav-button-slider next"
            onClick={() => sliderRef.current?.slickNext()}
          >
            &gt;
          </button>
          {isModalOpen && selectedSlide && (
            <div className="modal-overlay" ref={modalRef}>
              <div
                className="modal-content-m"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div id="close-m" onClick={closeModal}>
                  <i className="fas fa-times closeicon"></i>
                </div>
                <h2>{selectedSlide.heading}</h2>
                <p>{selectedSlide.text}</p>
              </div>
            </div>
          )}

          <div className="slidercontainer">
            <Slider ref={sliderRef} {...settings}>
              {slideData.map((slide) => (
                <div key={slide.id} className="slider-item">
                  <div className="slidecontents">
                    <h2 className="slideheading h2-np slidecontent">
                      {slide.heading}
                    </h2>
                    <p className="slidetext p-np slidecontent">{slide.text}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="section5">
        <h1 className="section5heading">How we are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Custom Implementation"
            description="Tailoring SmartVision implementation to fit your unique operational environment and requirements. "
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Comprehensive Training"
            description="Providing detailed training to ensure your team can fully leverage SmartVision’s capabilities. "
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Ongoing Support and Maintenance"
            description="Offering continuous support and maintenance to ensure optimal performance and up-to-date features."
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Feedback and Continuous Improvement"
            description=" Actively seeking customer feedback to continuously enhance and update the platform. "
            linkUrl=""
          />
        </div>
      </div>
    </div>
  );
}

export default AISmart;
