import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import Accordians from "./Accordians.js";

function Suply() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Comprehensive Expertise",
      text: " Our deep understanding of supply chain and logistics processes enables us to deliver superior solutions. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Implementations",
      text: "Tailoring solutions to perfectly fit your operational needs and business objectives. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Technology Leadership",
      text: "Leveraging the latest in ERP, IoT, and GPS technology to keep you ahead of the curve. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Proven Results",
      text: " A track record of delivering measurable improvements in efficiency, cost reduction, and operational performance. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "End-to-End Partnership",
      text: "From initial consultation to ongoing managed services, we're with you every step of the way. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "How can your solutions improve our supply chain operations?   ",
      content:
        "Our solutions enhance visibility, efficiency, and control over your supply chain, reducing costs, improving delivery times, and enabling data-driven decision-making. ",
    },
    {
      title: "Are your solutions scalable to our business size and needs?  ",
      content:
        "Absolutely. Our offerings are scalable and customizable to fit businesses of any size and adapt as your needs evolve.   ",
    },
    {
      title: "How do you ensure the security of our supply chain data? ",
      content:
        "We employ the latest in cybersecurity measures and adhere to stringent data protection protocols to safeguard your supply chain information.   ",
    },
    
    
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">
        {" "}
        The Purpose Behind Our supply Chain & logistics solutions.{" "}
      </p>
      At Expertit Data Informatics, we specialize in transforming your supply
      chain and logistics operations into streamlined, efficient systems.
      Leveraging cutting-edge technology and deep industry expertise, we offer
      comprehensive solutions that drive visibility, control, and optimization
      across your entire supply chain.
      <hr />
      <br /> <strong>Comprehensive Offerings</strong>
      Expertit Data Informatics provides a wide range of services covering
      various aspects of supply chain and logistics, attracting clients seeking
      comprehensive solutions.
      <br />
      <br />
      <strong> Expertise in Technology</strong>
      Expertit Data Informatics showcases expertise in SCM modules, GPS
      tracking, container tracking, and shipping management, emphasizing its
      commitment to enhancing logistics operations.
      <br />
      <br />
      <strong>Partnerships with Leading ERP Systems</strong> By partnering with
      MS Dynamics, SAP, NetSuite, and Odoo, Expertit Data Informatics ensures
      compatibility and integration with widely used software platforms,
      enhancing efficiency and interoperability.
      <br />
      <br />
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1">
                  {" "}
                  Supply Chain & Logistics Solutions{" "}
                </div>
                <div
                  className="component-paras"
                  style={{
                    color: "white",
                    marginLeft: "20px",
                    fontStyle: "italic",
                  }}
                >
                  "Streamlining Your Path to Efficiency – Advanced Supply Chain
                  & Logistics Solutions"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">
              What is Supply Chain & Logistics Solutions ?
            </div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                  fontSize: "25px",
                  fontFamily: "system-ui",
                }}
              >
                Our suite of Supply Chain and Logistics Solutions encompasses a
                broad range of services designed to enhance every aspect of your
                supply chain, from SCM modules and GPS tracking to container
                tracking and comprehensive shipping and logistics management.
                Partnering with leading ERP systems like MS Dynamics, SAP,
                NetSuite, and Odoo, we deliver both standard solutions and
                customized implementations, backed by our managed services and
                end-to-end support.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/generator.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong
                  style={{
                    paddingLeft: "15px",
                  }}
                >
                  {" "}
                  SCM Modules
                </strong>
                <p class="aligned-paragraph item3text">
                  Implementing and optimizing Supply Chain Management modules
                  for enhanced planning, operation, and collaboration.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft: "15px",
                }}
              >
                GPS Tracking and IoT Solutions
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Utilizing GPS and IoT technology for real-time tracking and
                monitoring of goods and assets.
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Container Tracking Solutions
              </strong>
              <p class="aligned-paragraph item3text">
                Offering advanced solutions for tracking containers across
                global supply chains, ensuring visibility and security.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Shipping and Logistics Management
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Comprehensive management solutions that streamline shipping
                processes, reduce costs, and improve delivery times.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                ERP System Implementations
              </strong>
              <p class="aligned-paragraph item3text">
                Expert implementation of ERP solutions from MS Dynamics, SAP,
                NetSuite, and Odoo, tailored to your supply chain needs.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Managed Services
              </strong>
              <p class="aligned-paragraph item3text">
                Ongoing support and management services to ensure your supply
                chain systems operate smoothly and efficiently.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                End-to-End Supply Chain Solutions
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                From procurement to delivery, providing complete solutions that
                optimize every stage of your supply chain.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          <div className="sliders">
            {/* Previous and Next buttons */}
            <button
              className="nav-button-slider prev"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              &lt;
            </button>
            <button
              className="nav-button-slider next"
              onClick={() => sliderRef.current?.slickNext()}
            >
              &gt;
            </button>
            {/* Modal */}
            {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

            <div className="slidercontainer">
              <Slider ref={sliderRef} {...settings}>
                {slideData.map((slide) => (
                  <div key={slide.id} className="slider-item">
                    <div className="slidecontents">
                      <h2 className="slideheading h2-np slidecontent">
                        {slide.heading}
                      </h2>
                      <p className="slidetext p-np slidecontent">
                        {slide.text}
                      </p>
                      {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* <CarouselSlider/> */}
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Initial Assessment"
              description=" Conducting a comprehensive analysis of your current supply chain and logistics operations.  "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Solution Design and Implementation"
              description=" Crafting a customized solution that integrates seamlessly with your operations and implementing it with minimal disruption. "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Training and Support"
              description=" Providing thorough training for your team and ongoing support to ensure smooth operation.  "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Continuous Improvement"
              description=" Regularly reviewing and optimizing the solution to ensure it continues to meet your evolving needs. "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>
      </div>
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default Suply;
