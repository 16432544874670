import React from "react";
import "./AIBenefits.css";

function AIBenefits() {
  return (
    <div>
      <img
        src="https://t4.ftcdn.net/jpg/03/02/96/41/360_F_302964106_1QhjddHXPZScF871xhLGLMIKishmku0c.jpg"
        className="d-block w-100"
        alt="slider first"
        style={{ height: "100%" }}
      />
      <div className="carousel-caption d-none d-md-block">
        <h1 className="AIheadings">AI/ML BENEFITS</h1>
        <p>
          The employment of AI/ML opens limitless possibilities for enterprises
          to inflate their customer experience with seamless workflows &
          personalization's.
        </p>
        <div class="row ai-card-container">
          <div class="col-md-3">
            <div class="AIcard">
              <div class="icon">
                <i class="fas fa-cogs"></i>
              </div>
              <div className="ai-headingsss">ADAPTABLE</div>
              <p className="ai-paragraph">
                Alter workflows easily as per business, market & customer
                changes with quick integration capabilities, while reducing
                human errors.
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="AIcard">
              <div class="icon">
                <i class="fas fa-user"></i>
              </div>
              <div className="ai-headingsss">PERSONALIZED</div>
              <p className="ai-paragraph">
                Continuous monitoring of customer transactions, interests, and
                credit score to chalk out customized offers, loans, etc.
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="AIcard">
              <div class="icon">
                <i class="fas fa-headset"></i>
              </div>
              <div className="ai-headingsss">INTELLIGENT SUPPORT</div>
              <p className="ai-paragraph">
                With quick resolution time and smooth flow of information,
                virtual assistants will redefine customer service & experience.
              </p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="AIcard">
              <div class="icon">
                <i class="fas fa-chart-bar"></i>
              </div>
              <div className="ai-headingsss">SMART INSIGHTS</div>
              <p>
                Instant analysis of huge data (transactions, payment
                authorizations, etc) providing insightful customer behavior to
                offer better service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIBenefits;
