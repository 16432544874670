import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
// import "./cyberCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordians from "./Accordians.js";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Database() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
  let items = [
    {
      title: "How do you ensure the scalability of our data solutions?",
      content:
        "We architect data solutions with scalability in mind, utilizing cloud-based technologies and modern data management practices to easily accommodate business growth and evolving data needs. ",
    },
    {
      title: "Can you help migrate our existing data to a new system?",
      content:
        "  Yes, our data integration and ETL services are designed to efficiently migrate data from existing systems to new databases or data warehouses, ensuring data integrity and minimal disruption. ",
    },
    {
      title: "What measures do you take to secure our data? ",
      content:
        " We prioritize data security by implementing advanced security protocols, including encryption, access controls, and regular security audits, to safeguard your data against unauthorized access and breaches. ",
    },
    {
      title: "How do you handle data compliance requirements? ",
      content:
        "Our team stays abreast of the latest data protection regulations and ensures that all data solutions comply with applicable laws and industry standards, such as GDPR and HIPAA. ",
    },
  ];
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <div className="modal-np">
        <div className="modal-sub-sk">
          <div className="row-sk">
            <div className="card-con-sk">
              <h4>
                Purpose of the Database/Data Warehouse Development & Managed
                Services{" "}
              </h4>
              <p>
                In the modern business landscape, data is the foundation of
                strategic decision-making. Our Database/Data Warehouse
                Development & Managed Services are designed to architect,
                implement, and manage sophisticated data solutions that empower
                businesses to harness the full potential of their data. We focus
                on creating scalable, secure, and efficient data infrastructures
                that enable real-time insights, operational efficiency, and a
                competitive edge in the marketplace.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <hr />
      <div className="modal-sub-sk">
      <div className="row-sk">
        <div className="card-con-sk">
            <h4>Purpose of the Odoo Managed Services</h4>
            <p>Welcome to ExpertIt Data Informatics, your trusted partner in cyber security audits and
      solutions. We offer a comprehensive range of services designed to assess,
      enhance, and protect your organization's digital ecosystem against cyber
      threats and vulnerabilities.</p>
        </div>
    </div>

<div className="row-sk">
        <div className="card-con-sk">
            <h4>What is a Frontend Develoment?</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam porro similique aliquid debitis ipsam soluta dolorum xchiszudgv mnviuh cvnkgh nvkdigh cnvilud viud vhg nvuix hvcd m xl ndhg iure.</p>
        </div>
    </div>

<div className="row-sk">
        <div className="card-con-sk">
            <h4>What is a Frontend Develoment?</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam porro similique aliquid debitis ipsam soluta dolorum ipsa! Voluptate, suscipit xchiszudgv mnviuh cvnkgh nvkdigh cnvilud viud vhg nvuix hvcd m xl ndhg fdjg vn diux nvidgh  vkgd nvmh iure.</p>
        </div>
    </div>




    </div> */}
      {/* <br/> <strong>Design, Development, and Implementation</strong>
 
           Custom software solutions tailored to organizational needs enhance operational efficiency and competitiveness through ADM Services.
         <br/><br/>
        <strong>Application Maintenance</strong>
        Ongoing maintenance ensures software functionality, security, and performance, meeting evolving business demands.
 
          <br/><br/><strong>System Integration</strong>
          Seamless communication between systems enhances operational efficiency and decision-making through ADM Services' system integration.
 
          <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">
                Database and Data Warehouse Managed Services{" "}
              </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empowering Data-Driven Decisions with Robust Data Solutions"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              Our service encompasses the end-to-end development and management
              of databases and data warehouses, tailored to meet the unique
              needs of your business. Leveraging the latest technologies and
              best practices, we ensure your data infrastructure is not only
              robust and reliable but also optimized for performance and
              scalability. From initial design and modeling to integration,
              analytics, and ongoing management, we provide comprehensive
              support to ensure your data ecosystem supports your business
              objectives now and into the future.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/15633.jpg"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Custom Database Development
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Design and development of bespoke databases tailored to specific
                business needs, ensuring optimal structure, performance, and
                security.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Data Warehouse Solutions
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Implementation of scalable data warehouse architectures to support
              complex data aggregation, analysis, and reporting requirements.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Data Integration and ETL Services
            </strong>
            <p class="aligned-paragraph item3text">
              Seamless integration of disparate data sources into a cohesive,
              unified system, utilizing ETL (Extract, Transform, Load) processes
              for data consolidation and preparation.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Performance Optimization
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Regular monitoring and optimization of database and data warehouse
              performance to ensure high efficiency and quick query responses.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Security and Compliance
            </strong>
            <p class="aligned-paragraph item3text">
              Implementation of stringent security measures, including data
              encryption and access controls, to protect sensitive information
              and ensure compliance with relevant regulations.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Managed Services
            </strong>
            <p class="aligned-paragraph item3text">
              Ongoing management, support, and maintenance of your data
              infrastructure to ensure reliability, security, and up-to-date
              functionality.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Data Migration and Modernization
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Our data migration and modernization services ensure seamless
              upgrades to your data infrastructure, minimizing downtime and
              ensuring data integrity. We handle all aspects of migration,
              leveraging the latest technologies for scalability and
              compatibility with evolving business needs, simplifying the data
              migration journey.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t "> USP of Services</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Tailored Data Strategies{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/organigram.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Tailored Data Strategies</h2>
                      <p>
                        Our approach is customized to your business needs,
                        ensuring that your data infrastructure aligns with your
                        strategic objectives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Advanced Technology Utilization
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Advanced Technology Utilization</h2>
                      <p>
                        Leveraging the latest technologies and platforms to
                        deliver cutting-edge data solutions that drive value.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Expert Team <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/approch.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expert Team</h2>
                      <p>
                        Our team of data professionals brings extensive
                        expertise in database and data warehouse development,
                        management, and optimization.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Security and Compliance Mastery
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        src="/Images/artificial-intelligence-sa.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Security and Compliance Mastery</h2>
                      <p>
                        We don’t just implement security measures; we ensure
                        that your data strategy adheres to the highest standards
                        of data privacy and compliance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Performance Excellence
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/connection.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Performance Excellence</h2>
                      <p>
                        Dedicated to optimizing performance, our services ensure
                        that your data systems operate efficiently, delivering
                        quick and reliable insights.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Comprehensive Managed Services
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/presentation.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Comprehensive Managed Services</h2>
                      <p>
                        Beyond development, our managed services provide ongoing
                        support and maintenance, ensuring your data ecosystem
                        remains robust and effective over time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
              {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
            </div>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
         
          </div>
   
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
   
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
   
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
   
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
   
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}

      {/* section 6 */}

      <div className="newsection5-np">
        <h1 className="section5heading"> Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Needs Assessment and Planning"
            text="Collaborating closely with clients to assess data needs, objectives, and challenges, leading to a strategic plan tailored to your business."
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="System Design and Development"
            text="Crafting a custom database/data warehouse solution, focusing on scalability, performance, and security from the outset. "
            img="/Images/prototype.png"
          />
          <SOP
            headding="Data Integration and Migration"
            text="Seamlessly integrating data from multiple sources, employing ETL processes to ensure data is clean, consistent, and ready for analysis. "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Testing and Optimization"
            text=" Rigorous testing of the data infrastructure for performance and reliability, followed by continuous optimization to ensure peak efficiency."
            img="/Images/report.png"
          />
          <SOP
            headding="Deployment and Training"
            text="Implementing the solution within your business environment, coupled with comprehensive training for your team on system use and best practices. "
            img="/Images/planning.png"
          />
          <SOP
            headding="Ongoing Management and Support"
            text="Providing continuous monitoring, management, and technical support to ensure the long-term success and evolution of your data infrastructure. "
            img="/Images/icons8-date-to-48.png"
          />
        </div>
      </div>

      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for the service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default Database;
