import React, { useState } from "react";

import { Link } from "react-router-dom";

import "./BusinessModal.css";

export default function ServiceModal() {
  return (
    <div>
      <div className="col-md-12 col-sm-12 col-xs-12">
        <h2 className="submenu-h2-heading mb10">Businesses </h2>
      </div>

      <div id="business-container" className="container">
        <div className="service-row">
          <div className="service-column">
            <div className="row">
              <Link to="/businesses/drone" className="abc">
                Drone Solution
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/businesses/finance" className="abc">
                Financial Services
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/service/publicsector" className="abc">
                Public Sector
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/businesses/Automative" className="abc">
                Automotive
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/service/educationalservice" className="abc">
                {" "}
                Education
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
          </div>

          <div className="service-column">
            <div className="row">
              <Link to="/businesses/healthcare" className="abc">
                Helthcare
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/service/informationservice" className="abc">
                Information Services
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/businesses/suply" className="abc">
                Logistics & Supplychain
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="businesses/ecommerce" className="abc">
                Retail & Ecommerce
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/businesses/digitalmarketing" className="abc">
                Digital Marketing & Strategy
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
          </div>
        </div>
      </div>
    </div>
  );
}
