import React from "react";
import "./AdvancedTollSurvey.css";

export default function Advanced() {
  return (
    <>
      <div className="advanced-container">
        <div className="saas-heading"> Advanced Toll Survey</div>

        <p className="section-description" style={{ marginLeft: "55px" }}>
          We deliver the Video Based Automatic Traffic Counting & Classification
          for National Highway applications.<br></br>
          Hindustan Informatics has introduced Advanced Technology for Traffic
          Counting and Classification (ATCC). The Solution is able to detect and
          classify the vehicles into different classes such as BIKE, CAR, LCV,
          HCV and OSV.
        </p>
        <div className="saas-products-container">
          <div className="advanced-card  transparent">
            <h3 style={{ paddingLeft: "11%" }}>HEIGHLIGHTS</h3>
            <ul style={{ paddingLeft: "6%" }}>
              <li>
                <p>1. Well suited to Mobile ATCC units</p>
              </li>
              <li>
                <p>
                  2. Advanced state of art Video & Image processing technology
                </p>
              </li>
              <li>
                <p>
                  3. ATCC is complete IP Video based solution that provides high
                  accuracy in real time vehicle counting and classification
                </p>
              </li>
              <li>
                <p>
                  4.Snapshot capture of each vehicle that is counted and
                  classified
                </p>
              </li>
              <li>
                <p>
                  5.Integrated with an Advanced Video Management software
                  facilitating maintenance of complete reporting and statistics
                </p>
              </li>
              <li>
                <p>6. 24x7 monitoring of traffic flow on national highways</p>
              </li>
            </ul>
          </div>
          <div className="saas-card  transparent">
            <img src="/Images/advaced-highlight.png"></img>
          </div>
        </div>
        <section className="pt75">
          <article className="scontainer">
            <div className="row col-md-12">
              <div
                className="col-md-9 col-md-offset-2 col-sm-12 col-xs-12 wow fadeInDown"
                data-wow-delay="0.3s"
              >
                <h3
                  id="page-innertitle"
                  className=" mb30 "
                  style={{ paddingBottom: "30px", paddingLeft: "12%" }}
                >
                  FEATURES
                </h3>
              </div>
            </div>
          </article>
        </section>
      </div>

      <div class="column">
        <ul style={{ textAlign: "left" }}>
          <li>
            Survey Report Available in the form of
            <ul>
              <li>1. CCTV footage of output video as proof</li>
              <li>
                2. One Pager report in standard format with shifts reports and
                High-level Graphics
              </li>
            </ul>
          </li>
          <li>
            Shift customization and time settings adjustable Dashboards with
            filter controls.
          </li>
          <li>
            Estimated Value Analysis can be done by putting values against the
            class rates
          </li>
          <li>
            Able to generate multiple reports in Profit and Loss analysis with
            variable inputs and multiple projections against input cost of
            vehicle class
          </li>
          <li>
            On-Site / Off-site support available in installation, commissioning
            and Software support
          </li>
          <li>
            Dynamic and Attractive dashboards with high speed and dynamic
            filtering and splitting options
          </li>
        </ul>
      </div>

      <div class="column">
        <ul style={{ textAlign: "left" }}>
          <li>
            Customized provision to get the Exemption and Expense report, helps
            to bid for route
          </li>
          <li>
            Can club multiple days in report for more accurate analysis against
            the intelligence generated
          </li>
          <li>
            Graphical representation of vehicle data against class makes report
            more readable
          </li>
          <li>
            Mobile Vehicle-mounted vehicle can read the live data and generate
            intelligence live
          </li>
          <li>Services of secret survey options available</li>
          <li>
            We deliver the service as per the client need. Customization of
            reports can be provided
          </li>
          <li>
            We are a company of values. Confidentiality agreement is strictly
            honored
          </li>
          <li>
            Advanced AI and Deep Learning Algorithms Packages with High Security
            and Big Data Systems integrated
          </li>
        </ul>
      </div>
    </>
  );
}
