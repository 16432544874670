import "./template.css";
import Accordians from "./Accordians.js";
import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

function Healthcare() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  let items = [
    {
      title:
        " How can your Medical Store Solutions improve our pharmacy operations?",
      content:
        " Our solutions automate key processes, enhance inventory management, and improve patient engagement, leading to increased efficiency and customer satisfaction. ",
    },
    {
      title: "What makes your B2B Commerce Platforms stand out?",
      content:
        " We focus on creating user-friendly, scalable platforms that support complex B2B transactions, personalized buying experiences, and integrated analytics for smarter business operations. ",
    },
    {
      title:
        " Can your Industrial Automation solutions be customized for our specific manufacturing needs? ",
      content:
        " Absolutely. We specialize in tailoring automation solutions that fit your unique manufacturing requirements, leveraging our partnerships with Siemens and Rockwell. ",
    },
    
  ];
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Customized Solutions",
      text: "Tailoring our offerings to meet the unique challenges and requirements of each sector we serve.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Technology Partnerships",
      text: "Partnering with industry leaders like Siemens and Rockwell to deliver state-of-the-art solutions. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Comprehensive Expertise",
      text: "Our team’s deep expertise across a range of industries enables us to provide innovative, effective solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Focus on Efficiency and Growth",
      text: "Our solutions are designed to streamline operations, enhance productivity, and drive business growth. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Advanced Analytics and Insights",
      text: "Leveraging data and behavior tracking to provide actionable insights for strategic decision-making. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">
        The Purpose Behind Our Healthcare & Pharma Solutions
      </p>
      Expertit Data Informatics is at the forefront of providing advanced,
      customized solutions across various sectors. From revolutionizing retail
      pharmacy operations and B2B commerce to pioneering industrial automation
      and insightful data analytics, our goal is to empower businesses with the
      tools they need for success in a rapidly evolving landscape.
      <hr />
      <br /> <strong>B2B Commerce Platforms</strong>
      The pharmaceutical industry often involves complex supply chains and B2B
      transactions between manufacturers, distributors, and retailers. By
      providing B2B commerce platforms tailored to the needs of pharmaceutical
      companies, Expertit Data Informatics facilitates smoother transactions,
      enhances collaboration among stakeholders, and improves the efficiency of
      the pharmaceutical supply chain.
      <br />
      <br />
      <strong> Industrial Automation with Partnerships</strong>
      Partnering with industry giants like Siemens and Rockwell for industrial
      automation signifies Expertit's capability to integrate advanced
      automation technologies into healthcare and pharmaceutical manufacturing
      processes. This collaboration aims to optimize production processes,
      ensure compliance with regulatory standards, and enhance product quality
      and safety in pharmaceutical manufacturing.
      <br />
      <br />
      <strong>Comprehensive Reporting and Data Collection Utilities</strong>
      Reporting and data collection are essential components of healthcare and
      pharmaceutical operations for tracking performance, monitoring compliance,
      and making informed decisions. By offering comprehensive reporting and
      data collection utilities, Expertit Data Informatics enables healthcare
      organizations and pharmaceutical companies to gather, analyze, and utilize
      data effectively to improve operational efficiency and outcomes.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1">
                  Healthcare & Pharma Solutions{" "}
                </div>
                <div
                  className="component-paras"
                  style={{
                    color: "white",
                    marginLeft: "20px",
                    fontStyle: "italic",
                  }}
                >
                  "Innovating Across Industries – Tailored Solutions for
                  Tomorrow’s Challenges"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Healthcare & Pharma Solutions?</div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                  fontSize: "25px",
                  fontFamily: "system-ui",
                }}
              >
                Our suite of solutions spans critical areas of modern business
                needs, including Medical Store management, B2B commerce
                platforms, Industrial Automation partnering with giants,
                comprehensive Reporting and Data Collection utilities, and
                Behavior Tracking with our proprietary SmartVision technology.
                At Expertit Data Informatics, we leverage cutting-edge
                technologies and deep industry insights to deliver solutions
                that enhance efficiency, drive growth, and transform operational
                capabilities.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/medical-insurance.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{ paddingLeft: "20px" }}>
                  Medical Store Solutions
                </strong>
                <p class="aligned-paragraph item3text">
                  Implementing technology-driven solutions for pharmacy
                  management, inventory control, and digital prescriptions to
                  enhance patient care and store efficiency.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft: "15px",
                  color: "black",
                }}
              >
                B2B Commerce Platforms
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Developing robust B2B commerce solutions that streamline
                operations, enhance buyer experiences, and drive sales.
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class="item32-heading"
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Industrial Automation with Siemens and Rockwell
              </strong>
              <p class="aligned-paragraph item3text">
                Offering advanced industrial automation solutions, integrating
                the latest technologies from Siemens and Rockwell to optimize
                manufacturing and production processes.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Reporting and Data Collection Utility
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                Providing powerful tools for data collection, analysis, and
                reporting to inform business decisions and improve operational
                insights.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                {" "}
                Behavior Tracking with SmartVision
              </strong>
              <p class="aligned-paragraph item3text">
                Utilizing our SmartVision technology for advanced behavior
                tracking applications, improving security, customer insights,
                and operational efficiency.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                User-Friendly Interface
              </strong>
              <p class="aligned-paragraph item3text">
                Intuitive interfaces and user-friendly design elements make the
                solutions easy to use and navigate, minimizing training
                requirements and ensuring widespread adoption among staff
                members.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Supply Chain Visibility and Traceability
              </strong>
              <p class="aligned-paragraph item3text" style={{ color: "black" }}>
                To ensure transparency in healthcare and pharmaceutical supply
                chains, allowing stakeholders to track products from
                manufacturing to delivery, ensuring product integrity,
                compliance, and efficiency.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          <div className="sliders">
            {/* Previous and Next buttons */}
            <button
              className="nav-button-slider prev"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              &lt;
            </button>
            <button
              className="nav-button-slider next"
              onClick={() => sliderRef.current?.slickNext()}
            >
              &gt;
            </button>
            {/* Modal */}
            {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

            <div className="slidercontainer">
              <Slider ref={sliderRef} {...settings}>
                {slideData.map((slide) => (
                  <div key={slide.id} className="slider-item">
                    <div className="slidecontents">
                      <h2 className="slideheading h2-np slidecontent">
                        {slide.heading}
                      </h2>
                      <p className="slidetext p-np slidecontent">
                        {slide.text}
                      </p>
                      {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* <CarouselSlider/> */}
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Solution Development and Deployment"
              description="Custom-developing solutions that align with your business goals and seamlessly integrating them into your operations. "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Continuous Support and Optimization"
              description="Providing ongoing support and continually optimizing solutions to ensure they deliver maximum value."
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Training and Empowerment"
              description="Offering comprehensive training to ensure your team can fully leverage the new technologies and processes."
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Future-Ready Focus"
              description="Keeping our solutions agile and adaptable to ensure they remain effective as your business and the industry evolve."
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>
      </div>
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for the service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default Healthcare;
